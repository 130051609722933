<script>
import {statStore} from "@/stores/Statistic.store";
import Statistic from "@/components/common/Statistic.vue";
import GameDate from "@/components/common/GameDate.vue";
import GameHour from "@/components/common/GameHour.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import GameScore from "@/components/common/GameScore.vue";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import TeamName from "@/components/common/TeamName.vue";
import {gameStore} from "@/stores/Game.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "StatisticModalRow",
  components: {TeamName, GameResultCircle, GameScore, LeagueLogo, TeamLogo, GameHour, GameDate, Statistic},

  props: {
    gameUid: {
      required: true,
    },
    stat: {
      required: true,
    },
    period: {
      required: true,
    }
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid);
    },
    _leagueUID() {
      if(!this._game) return null;
      return this._game.leagueUID;
    },
    _homeTeamUID(){
      return gameStore().getHomeTeamUID(this._game);
    },
    _awayTeamUID(){
      return gameStore().getAwayTeamUID(this._game);
    },
    _whoUID(){
      if(!this.stat) return null;
      return this.stat.whoUID
    },
    _matchingGames() {
      if (!this.stat) return [];
      return statStore().getDataUIDs(this.stat, this.period, true)
    },
    _scoreFtHome() {
      if(!this._game) return null;
      return this._game.scoreFtHome
    },
    _scoreFtAway() {
      if(!this._game) return null;
      return this._game.scoreFtAway
    },

    _winAtHome() {
      return this._scoreFtHome > this._scoreFtAway && this._whoUID === this._homeTeamUID
    },

    _loseAtHome() {
      return this._scoreFtHome < this._scoreFtAway && this._whoUID === this._homeTeamUID
    },

    _winAtAway() {
      return this._scoreFtHome < this._scoreFtAway && this._whoUID === this._awayTeamUID
    },

    _loseAtAway() {
      return this._scoreFtHome > this._scoreFtAway && this._whoUID === this._awayTeamUID
    },

    _offers() {
      if(!this._game) {
        return []
      }

      const offers = []
      if(this._game.odd1X2HomeValue) {
        offers.push({
          value:this._game.odd1X2HomeValue.toFixed(2),
          classes: {
            'positive': this._winAtHome,
            'negative': this._loseAtHome
          }
        })
      }
      if(this._game.odd1X2DrawValue) {
        offers.push({
          value:this._game.odd1X2DrawValue.toFixed(2),
          classes: {
            'tertiary': this._scoreFtHome === this._scoreFtAway
          }
        })
      }
      if(this._game.odd1X2AwayValue) {
        offers.push({
          value:this._game.odd1X2AwayValue.toFixed(2),
          classes: {
            'positive': this._winAtAway,
            'negative': this._loseAtAway
          }
        })
      }
      return offers
    },
    _isGameMatching() {
      if (!this._matchingGames) return null;
      if (this._matchingGames.length === 0) return null;
      return this._matchingGames.includes(this.gameUid)
    },
    _rowClasses() {
      let isGameMatching = this._isGameMatching;
      return {
        'statistic-row-game': true,
        'matching': isGameMatching === true,
        'not-matching': isGameMatching === false,
      }
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
  },

}
</script>

<template>

  <table :class="_rowClasses">
    <tr>
      <td class="date" rowspan="2" style="width: 64px">
        <game-date :uid="gameUid"/>
      </td>
      <td class="league" rowspan="2" style="width: 32px">
        <league-logo :uid="_leagueUID"/>
      </td>
      <td class="offers" rowspan="2" style="width: 64px">
        <button v-for="offer in _offers" class="small" :class="offer.classes" style="height: 16px; line-height: 16px !important;">
          {{offer.value}}
        </button>
      </td>
      <td class="team-logo" style="width: 32px">
        <team-logo :uid="_homeTeamUID" size="small"/>
      </td>
      <td class="team-name" style="text-align: left">
        <team-name :uid="_homeTeamUID"/>
      </td>

      <td class="score" style="width: 32px">{{ _scoreFtHome }}</td>
    </tr>
    <tr>
      <td class="team-logo">
        <team-logo :uid="_awayTeamUID" size="small"/>
      </td>
      <td class="team-name" style="text-align: left">
        <team-name :uid="_awayTeamUID"/>
      </td>
      <td class="score">{{ _scoreFtAway }}</td>
    </tr>
  </table>
  <!--
  <tr :class="_rowClasses(gameUid)">
    <td>
      <game-date :uid="gameUid" style="min-width: 44px"/>
    </td>
    <td class=" text-right">
      <team-name :uid="_homeTeamUID" :highlight="_whoUID === _homeTeamUID"/>
    </td>
    <td class="text-center">
      <team-logo :uid="_homeTeamUID"/>
    </td>
    <td class="text-center">
      {{_game.odd1X2HomeValue}}
      <game-score :uid="gameUid"/>
    </td>
    <td class="text-center">
      <team-logo :uid="_awayTeamUID"/>
    </td>
    <td>
      <team-name :uid="_awayTeamUID" :highlight="_whoUID === _awayTeamUID" class="flex-grow text-left"/>
    </td>
    <td>
      <game-result-circle :team-uid="_whoUID" :game-uid="gameUid"/>
    </td>
  </tr>-->
</template>

<style scoped lang="less">

.statistic-row-game{
  &.matching {
    tr {
      background-color: #0F9D5811 !important;
      border: none;
    }
  }

  &.not-matching {
    tr {
      background-color: #FF000011 !important;
      border: none;
    }
  }
  border-bottom: 1px solid black;
}


</style>