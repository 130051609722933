<script>
import {GridItem, GridLayout} from 'vue3-grid-layout-next'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

// Widgets
import ProfilerContinuousValueWidget
  from "@/components/routes/profiler/panes/analyse/perspective/widgets/Profiler.ContinuousValue.Widget.vue";


export default {
  name: "ProfilerAnalysePerspective",
  components: {
    FontAwesomeIcon,
    GridItem, GridLayout,

    // Widgets
    ProfilerContinuousValueWidget
  },


  mounted() {
  },

  computed: {
    _widgets() {
      return [
        {
          uid: 'profiler-percentage-widget-homeWinOdd',
          type: 'ProfilerContinuousValueWidget',
          x: 0, y: 0,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeWinOdd'
        },
        {
          uid: 'profiler-percentage-widget-drawOdd',
          type: 'ProfilerContinuousValueWidget',
          x: 20, y: 0,
          dimensions: {
            width: 20, height: 10
          },
          property : 'drawOdd'
        },
        {
          uid: 'profiler-percentage-widget-awayWinOdd',
          type: 'ProfilerContinuousValueWidget',
          x: 40, y: 0,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayWinOdd'
        },
        {
          uid: 'profiler-percentage-widget-homeWinProbability',
          type: 'ProfilerContinuousValueWidget',
          x: 0, y: 10,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeWinProbability'
        },
        {
          uid: 'profiler-percentage-widget-drawProbability',
          type: 'ProfilerContinuousValueWidget',
          x: 20, y: 10,
          dimensions: {
            width: 20, height: 10
          },
          property : 'drawProbability'
        },
        {
          uid: 'profiler-percentage-widget-awayWinProbability',
          type: 'ProfilerContinuousValueWidget',
          x: 40, y: 10,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayWinProbability'
        },
        {
          uid: 'profiler-percentage-widget-bttsYesOdd',
          type: 'ProfilerContinuousValueWidget',
          x: 0, y: 20,
          dimensions: {
            width: 20, height: 10
          },
          property : 'bttsYesOdd'
        },
        {
          uid: 'profiler-percentage-widget-bttsNoOdd',
          type: 'ProfilerContinuousValueWidget',
          x: 20, y: 20,
          dimensions: {
            width: 20, height: 10
          },
          property : 'bttsNoOdd'
        },
        {
          uid: 'profiler-percentage-widget-bttsYesProbability',
          type: 'ProfilerContinuousValueWidget',
          x: 40, y: 20,
          dimensions: {
            width: 20, height: 10
          },
          property : 'bttsYesProbability'
        },
        {
          uid: 'profiler-percentage-widget-bttsNoProbability',
          type: 'ProfilerContinuousValueWidget',
          x: 60, y: 20,
          dimensions: {
            width: 20, height: 10
          },
          property : 'bttsNoProbability'
        },

        {
          uid: 'profiler-percentage-widget-under25Odd',
          type: 'ProfilerContinuousValueWidget',
          x: 0, y: 30,
          dimensions: {
            width: 20, height: 10
          },
          property : 'under25Odd'
        },
        {
          uid: 'profiler-percentage-widget-over25Odd',
          type: 'ProfilerContinuousValueWidget',
          x: 20, y: 30,
          dimensions: {
            width: 20, height: 10
          },
          property : 'over25Odd'
        },
        {
          uid: 'profiler-percentage-widget-under25Probability',
          type: 'ProfilerContinuousValueWidget',
          x: 40, y: 30,
          dimensions: {
            width: 20, height: 10
          },
          property : 'under25Probability'
        },
        {
          uid: 'profiler-percentage-widget-over25Probability',
          type: 'ProfilerContinuousValueWidget',
          x: 60, y: 30,
          dimensions: {
            width: 20, height: 10
          },
          property : 'over25Probability'
        },

        {
          uid: 'profiler-percentage-widget-homeBttsRateSeasonAtHome',
          type: 'ProfilerContinuousValueWidget',
          x: 0, y: 40,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeBttsRateSeasonAtHome'
        },
        {
          uid: 'profiler-percentage-widget-homeBttsRateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 20, y: 40,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeBttsRateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayBttsRateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 40, y: 40,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayBttsRateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayBttsRateSeasonAtAway',
          type: 'ProfilerContinuousValueWidget',
          x: 60, y: 40,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayBttsRateSeasonAtAway'
        },

        {
          uid: 'profiler-percentage-widget-homeWinRateSeasonAtHome',
          type: 'ProfilerContinuousValueWidget',
          x: 0, y: 50,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeWinRateSeasonAtHome'
        },
        {
          uid: 'profiler-percentage-widget-homeWinRateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 20, y:50,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeWinRateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayWinRateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 40, y: 50,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayWinRateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayWinRateSeasonAtAway',
          type: 'ProfilerContinuousValueWidget',
          x: 60, y: 50,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayWinRateSeasonAtAway'
        },

        {
          uid: 'profiler-percentage-widget-homeLoseRateSeasonAtHome',
          type: 'ProfilerContinuousValueWidget',
          x: 0, y: 60,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeLoseRateSeasonAtHome'
        },
        {
          uid: 'profiler-percentage-widget-homeLoseRateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 20, y:60,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeLoseRateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayLoseRateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 40, y: 60,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayLoseRateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayLoseRateSeasonAtAway',
          type: 'ProfilerContinuousValueWidget',
          x: 60, y: 60,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayLoseRateSeasonAtAway'
        },
        {
          uid: 'profiler-percentage-widget-homeOver05RateSeasonAtHome',
          type: 'ProfilerContinuousValueWidget',
          x: 0, y: 70,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeOver05RateSeasonAtHome'
        },
        {
          uid: 'profiler-percentage-widget-homeOver05RateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 20, y: 70,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeOver05RateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayOver05RateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 40, y: 70,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayOver05RateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayOver05RateSeasonAtAway',
          type: 'ProfilerContinuousValueWidget',
          x: 60, y: 70,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayOver05RateSeasonAtAway'
        },

        
        {
          uid: 'profiler-percentage-widget-homeOver15RateSeasonAtHome',
          type: 'ProfilerContinuousValueWidget',
          x: 0, y: 80,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeOver15RateSeasonAtHome'
        },
        {
          uid: 'profiler-percentage-widget-homeOver15RateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 20, y: 80,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeOver15RateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayOver15RateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 40, y: 80,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayOver15RateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayOver15RateSeasonAtAway',
          type: 'ProfilerContinuousValueWidget',
          x: 60, y: 80,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayOver15RateSeasonAtAway'
        },

        {
          uid: 'profiler-percentage-widget-homeOver25RateSeasonAtHome',
          type: 'ProfilerContinuousValueWidget',
          x: 0, y: 90,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeOver25RateSeasonAtHome'
        },
        {
          uid: 'profiler-percentage-widget-homeOver25RateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 20, y: 90,
          dimensions: {
            width: 20, height: 10
          },
          property : 'homeOver25RateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayOver25RateSeason',
          type: 'ProfilerContinuousValueWidget',
          x: 40, y: 90,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayOver25RateSeason'
        },
        {
          uid: 'profiler-percentage-widget-awayOver25RateSeasonAtAway',
          type: 'ProfilerContinuousValueWidget',
          x: 60, y: 90,
          dimensions: {
            width: 20, height: 10
          },
          property : 'awayOver25RateSeasonAtAway'
        },
          
      ]
    },
    _layout() {
      const layout = []
      if (!this._widgets) return layout

      this._widgets.forEach((widget) => {
        layout.push({
          'i': widget.uid,
          'uid': widget.uid,
          'x': widget.x, 'y': widget.y,
          'w': widget.dimensions.width, 'h': widget.dimensions.height,
          'type': widget.type,
          'property': widget.property
        })
      })
      return layout
    }
  },
  methods: {

  }
}
</script>

<template>
  <div id="analyse-perspective-wrapper">
    <div id="analyse-perspective">
      <grid-layout :layout.sync='_layout'
                   :col-num='128'
                   :is-draggable='false'
                   :row-height='8'
                   :is-resizable='false'
                   :vertical-compact='false'
                   :use-css-transforms='true'>
        <grid-item v-for='item in _layout' class="widget"
                   :x='item.x'
                   :y='item.y'
                   :w='item.w'
                   :h='item.h'
                   :i='item.i'>
          <component :is="item.type" :item="item"/>
        </grid-item>
      </grid-layout>
    </div>
  </div>

</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#analyse-perspective-wrapper {
  height: calc(100dvh - @toolbar-regular  - 5px);
  width: calc(100vw - 400px - 3 * @padding-small);
  overflow: auto;
  scrollbar-width: thin;

  #analyse-perspective {
    height: 2096px;
    width: 2096px;
    overflow: hidden;
  }
}

#empty-perspective, #forbidden-game-perspective {
  margin: auto;
  width: calc(100vw - 400px);

  p {
    margin: 200px auto @padding-large auto;
    max-width: 400px;
    text-align: center;
    font-size: 2em !important;
    color: @text-color;
    opacity: 0.1;
  }
}

#forbidden-game-perspective {
  p {
    color: @warning-color;
    opacity: 1;
  }
}
</style>