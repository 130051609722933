<script>
import {statStore} from "@/stores/Statistic.store";
import Statistic from "@/components/common/Statistic.vue";
import GameDate from "@/components/common/GameDate.vue";
import GameHour from "@/components/common/GameHour.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import GameScore from "@/components/common/GameScore.vue";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import TeamName from "@/components/common/TeamName.vue";
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import StatisticModalRow from "@/components/common/modal/statistic/StatisticModal.Row.vue";

export default {
  name: "StatisticModal",
  components: {
    StatisticModalRow,
    TeamName, GameResultCircle, GameScore, LeagueLogo, TeamLogo, GameHour, GameDate, Statistic},
  data() {
    return {
      period: 'ALL_TIME',
    }
  },

  computed: {
    _statStore() {
      return statStore();
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'statisticModal';
    },
    _payload() {
      if (!this._isVisible) return null;
      const payload = modalStore().getVisibleModal.payload
      if (!payload) return null;
      return payload;
    },
    _isStatCombined() {
      if (!this._payload) return false;
      if(!this._payload.uid) return false;

      // On convertit en string
      const uidAsString = this._payload.uid.toString();

      // La stat est combiné si l'uid contient un + ou un x
      return uidAsString.indexOf('+') >= 0 || uidAsString.indexOf('x') >= 0
    },
    _statUID() {
      if (!this._payload) return null;
      if (!this._payload.uid) return null;
      return this._payload.uid
    },
    _games() {
      if (!this._stat) return [];
      return statStore().getDataUIDs(this._stat, this.period, false)
    },
    _matchingGames() {
      if (!this._stat) return [];
      return statStore().getDataUIDs(this._stat, this.period, true)
    },
    _stat() {
      if (!this._statUID) return null;
      return this._statStore.get(this._statUID)
    },
    _isNotSerie() {
      if (!this._stat) return false;
      return this._stat.type !== 'SERIE'
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _isGameMatching(g) {
      if (!this._matchingGames) return null;
      if (this._matchingGames.length === 0) return null;
      return this._matchingGames.includes(g)
    },
    _rowClasses(g) {
      let isGameMatching = this._isGameMatching(g);
      return {
        'matching': isGameMatching === true,
        'not-matching': isGameMatching === false,
      }
    }
  },
  watch: {
    _statUID() {
      if (!this._payload) return null;
      this.period = this._payload.period

      // On va chercher la stat
      statStore().get(this._statUID)
    },

  },
}
</script>

<template>
  <AModal v-if="_isVisible && _stat" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <div class="loading-div" v-if="!_stat"></div>
    <header class="ant-modal-header">
      Statistique <span v-if="_isStatCombined">combinée</span>
    </header>

    <div class="content">
      <statistic :uid="_statUID" :period="period" v-if="!_isStatCombined"/>
      <hr/>
      <statistic-modal-row :stat="_stat" :game-uid="g" :period="period" v-for="g in _games" :key="g"/>
    </div>

    <template #footer>
      <div class="flex  m-2">

        <div class="flex-grow text-left">
          <button class="no-radius tab" :class="{'selected' : period === 'ALL_TIME'}" v-if="!_isStatCombined"
                  :disabled="_statStore.getDataCount(_stat, 'ALL_TIME') === '-'"
                  @click="period='ALL_TIME'">
            Toujours ({{ _statStore.getDataCount(_stat, 'ALL_TIME') }})
          </button>
          <button class="no-radius tab" :class="{'selected' : period === 'CURRENT_SEASON'}"
                  :disabled="_statStore.getDataCount(_stat, 'CURRENT_SEASON') === '-'"
                  @click="period='CURRENT_SEASON'">
            Saison ({{ _statStore.getDataCount(_stat, 'CURRENT_SEASON') }})
          </button>
          <button class="no-radius tab" :class="{'selected' : period === 'LAST_10_GAMES'}" v-if="_isNotSerie"
                  :disabled="_statStore.getDataCount(_stat, 'LAST_10_GAMES') === '-'"
                  @click="period='LAST_10_GAMES'">
            10 derniers
          </button>
          <button class="no-radius tab" :class="{'selected' : period === 'LAST_5_GAMES'}" v-if="_isNotSerie"
                  :disabled="_statStore.getDataCount(_stat, 'LAST_5_GAMES') === '-'"
                  @click="period='LAST_5_GAMES'">
            5 derniers
          </button>
        </div>
      </div>

    </template>

  </AModal>
</template>

<style scoped lang="less">

.matching {
  background-color: #0F9D5822;
}

.not-matching {
  background-color: #FF000011;
}
</style>