<script>
import axiosService from "@/services/axios.service";
import {perspectiveStore} from "@/stores/Perspective.store";
import {contextStore} from "@/stores/Context.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import SimpleGameRow from "@/components/routes/selection/panes/selection/items/tabs/SimpleGameRow.vue";
import WidgetParamButton from "@/components/common/widgets/parameter/WidgetParamButton.vue";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import {gameStore} from "@/stores/Game.store";

export default {
  name: "SimilarWidget",
  components: {
    WidgetPeriodParamButton,
    WidgetParamButton,
    SimpleGameRow,
    WidgetLoadingContent
  },
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      // Les données récupéré du server
      data: null,
    }
  },
  mounted() {
    this._onGameUIDChange()

    if (!this.item) return;  // widget store preview
    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })
    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _hasFullLicence() {
      return contextStore().hasFullLicence()
    },
    _dataValid() {
      return this.data && this.data.gameUIDs && this.data.gameUIDs.length > 0
    },
    _gameUIDs() {
      if (!this.data) return []
      return this.data.gameUIDs
    },
    _gameUIDsLength() {
      return this._gameUIDs.length
    },
    _homeWinRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.homeWinGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _awayWinRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.awayWinGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _drawRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.drawGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over05Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over05Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over15Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over15Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over25Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over25Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _over35Rate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.over35Games.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _bttsRate() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.bttsGames.length / this._gameUIDsLength * 100.0).toFixed(0)
    },
    _goalsSum() {
      if (!this.data) return 0
      return this.data.homeGoalsCount + this.data.awayGoalsCount
    },
    _goalsAvg() {
      if (this._gameUIDsLength === 0) return 0
      return (this._goalsSum / this._gameUIDsLength).toFixed(2)
    },
    _homeGoalsAvg() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.homeGoalsCount / this._gameUIDsLength).toFixed(2)
    },
    _awayGoalsAvg() {
      if (this._gameUIDsLength === 0) return 0
      return (this.data.awayGoalsCount / this._gameUIDsLength).toFixed(2)
    },
    _scoreRates() {
      if (!this.data) return []
      const results = {}
      Object.keys(this.data.scoreCountMap).forEach(item => {
        results[item] = (this.data.scoreCountMap[item].length / this._gameUIDsLength)
      })
      return results
    },
    _topScoresRate() {
      // sort _scoreRates by value
      const sorted = Object.keys(this._scoreRates).sort((a, b) => this._scoreRates[b] - this._scoreRates[a])
      return sorted.slice(0, 6)
    },
    _getHighestProbaScore() {
      if (!this._scoreRates) return 1
      return this._scoreRates[this._topScoresRate[0]]
    },
    _momentParamValue() {
      return perspectiveStore().getParameterValue(this.item.uid, 'moment') || 'FT'
    },
    _game() {
      return gameStore().get(contextStore().gameUID)
    }
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!contextStore().gameUID) {
        return;
      }

      if (!this._hasFullLicence) {
        return;
      }

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) {
        return;
      }

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {
      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        return;
      }

      const payload = {
        gameUID: contextStore().gameUID,
        moment: perspectiveStore().getParameterValue(this.item.uid, 'moment') || 'FT',
        on1X2: perspectiveStore().getParameterDoubleValue(this.item.uid, 'on_1x2') > 0.00,
        on1X2Margin: perspectiveStore().getParameterDoubleValue(this.item.uid, 'on_1x2'),
        onUnderOver25: perspectiveStore().getParameterDoubleValue(this.item.uid, 'on_uo25') > 0.00,
        onUnderOver25Margin: perspectiveStore().getParameterDoubleValue(this.item.uid, 'on_uo25'),
        onBTTS: perspectiveStore().getParameterDoubleValue(this.item.uid, 'on_btts') > 0.00,
        onBTTSMargin: perspectiveStore().getParameterDoubleValue(this.item.uid, 'on_btts'),
        onLeague: perspectiveStore().getParameterDoubleValue(this.item.uid, 'on_league'),
        onHomeTeam: perspectiveStore().getParameterDoubleValue(this.item.uid, 'on_home_team'),
        onAwayTeam: perspectiveStore().getParameterDoubleValue(this.item.uid, 'on_away_team'),
        odd1X2HomeValue: this._game.odd1X2HomeValue,
        odd1X2DrawValue: this._game.odd1X2DrawValue,
        odd1X2AwayValue: this._game.odd1X2AwayValue,
        oddUO25UnderValue: this._game.oddUO25UnderValue,
        oddUO25OverValue: this._game.oddUO25OverValue,
        oddBttsYesValue: this._game.oddBttsYesValue,
        oddBttsNoValue: this._game.oddBttsNoValue
      }
      axiosService.put('/wid/similar-games', payload).then(response => {
        this.data = response.data
        wds.put(this.item.uid, contextStore().gameUID, this.data)
      })
    },
    _roundProba(proba) {
      return (parseFloat(proba) * 100.0).toFixed(1) + '%'
    },
    _getProbaScoreColorTransparency(proba) {
      let opacity = proba / this._getHighestProbaScore;
      opacity = Math.pow(opacity, 2)
      return `rgba(0, 128, 0, ${opacity})`
    },
    _probaToOdd(proba) {
      if (parseFloat(proba) === 0.0) return '-.--'
      return (1.0 / (proba / 100.0)).toFixed(2)
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Match similaires
      <div class="widget-header-params flex" v-if="item">
        <widget-param-button :widget="item"/>
      </div>
    </div>

    <div v-if="_hasFullLicence">
      <widget-loading-content v-if="!data"/>
      <div class="widget-content flex flex-col" v-else>
        <div>
          <div v-if="_dataValid">
            <hr/>
            <a-alert v-if="_gameUIDsLength > 0 && _gameUIDsLength < 100" class="m-1"
                     message="Minimum de 100 matchs similaires recommandé." type="warning" show-icon/>
            <div class="flex text-left">
              <legend class="m-0 ml-2 white" style="height: 22.5px; line-height: 22.5px">{{ _gameUIDsLength }} matchs similaires</legend>
              <div class="flex-grow"/>
              <button :class="{'small no-radius' : true}" style="height: 22.5px" disabled>
                ({{ _momentParamValue }})
              </button>
            </div>
            <fieldset class="m-1 text-left no-border">
              <table>
                <tr>
                  <th></th>
                  <th style="width: 40px">1</th>
                  <th style="width: 40px">X</th>
                  <th style="width: 40px" class="sr">2</th>
                  <th>+0.5</th>
                  <th>+1.5</th>
                  <th>+2.5</th>
                  <th class="sr">+3.5</th>
                  <th style="width: 45px">BTTS</th>
                </tr>
                <tr>
                  <td>%</td>
                  <td>{{ _homeWinRate }}%</td>
                  <td>{{ _drawRate }}%</td>
                  <td class="sr">{{ _awayWinRate }}%</td>
                  <td style="width: 32px">{{ _over05Rate }}%</td>
                  <td style="width: 32px">{{ _over15Rate }}%</td>
                  <td style="width: 32px">{{ _over25Rate }}%</td>
                  <td class="sr" style="width: 40px">{{ _over35Rate }}%</td>
                  <td style="width: 40px">{{ _bttsRate }}%</td>
                </tr>
                <tr>
                  <td>Val</td>
                  <td>{{ _probaToOdd(_homeWinRate) }}</td>
                  <td>{{ _probaToOdd(_drawRate) }}</td>
                  <td class="sr">{{ _probaToOdd(_awayWinRate) }}</td>
                  <td>{{ _probaToOdd(_over05Rate) }}</td>
                  <td>{{ _probaToOdd(_over15Rate) }}</td>
                  <td>{{ _probaToOdd(_over25Rate) }}</td>
                  <td class="sr">{{ _probaToOdd(_over35Rate) }}</td>
                  <td>{{ _probaToOdd(_bttsRate) }}</td>
                </tr>
              </table>
            </fieldset>

            <fieldset class="m-1 text-left no-border">
              <table>
                <tr>
                  <th></th>
                  <th style="width: 40px">1X</th>
                  <th style="width: 40px">12</th>
                  <th style="width: 40px" class="sr">X2</th>
                  <th>-0.5</th>
                  <th>-1.5</th>
                  <th>-2.5</th>
                  <th class="sr">-3.5</th>
                  <th style="width: 45px">!BTTS</th>
                </tr>
                <tr>
                  <td>%</td>
                  <td>{{ 100 - _awayWinRate }}%</td>
                  <td>{{ 100 - _drawRate }}%</td>
                  <td class="sr">{{ 100 - _homeWinRate }}%</td>
                  <td style="width: 32px">{{ 100 - _over05Rate }}%</td>
                  <td style="width: 32px">{{ 100 - _over15Rate }}%</td>
                  <td style="width: 32px">{{ 100 - _over25Rate }}%</td>
                  <td class="sr" style="width: 40px">{{ 100 - _over35Rate }}%</td>
                  <td style="width: 40px">{{ 100 - _bttsRate }}%</td>
                </tr>
                <tr>
                  <td>Val</td>
                  <td>{{ _probaToOdd(100 - _awayWinRate) }}</td>
                  <td>{{ _probaToOdd(100 - _drawRate) }}</td>
                  <td class="sr">{{ _probaToOdd(100 - _homeWinRate) }}</td>
                  <td>{{ _probaToOdd(100 - _over05Rate) }}</td>
                  <td>{{ _probaToOdd(100 - _over15Rate) }}</td>
                  <td>{{ _probaToOdd(100 - _over25Rate) }}</td>
                  <td class="sr">{{ _probaToOdd(100 - _over35Rate) }}</td>
                  <td>{{ _probaToOdd(100 - _bttsRate) }}</td>
                </tr>
              </table>
            </fieldset>

            <div>
              <fieldset class="m-1 no-border mt-3 text-left p-0">
                <legend class="m-0">Nombre de buts</legend>
                <table>
                  <tr>
                    <th>Tot</th>
                    <th>Dom</th>
                    <th>Ext</th>

                  </tr>
                  <tr>
                    <td style="width: 32px">{{ _goalsAvg }}</td>
                    <td style="width: 32px">{{ _homeGoalsAvg }}</td>
                    <td style="width: 32px">{{ _awayGoalsAvg }}</td>

                  </tr>
                </table>
              </fieldset>
              <fieldset class="m-1 mt-3 text-left no-border">
                <legend class="m-0">Scores fréquents</legend>
                <div class="mb-2">
                  <a-button-group v-for="score in _topScoresRate" class="m-1 inline-block">
                    <button class="info no-radius-right">
                      {{ score }}
                    </button>
                    <button class="no-radius-left"
                            :style="{'backgroundColor': _getProbaScoreColorTransparency(_scoreRates[score])}">
                      {{ _roundProba(_scoreRates[score]) }}
                    </button>
                  </a-button-group>
                </div>
              </fieldset>
            </div>
          </div>
          <a-alert v-else-if="!data" message="Chargement..." type="info" class="m-2"/>
          <a-alert v-else type="error" class="m-2" show-icon
                   message="Aucune donnée disponible ne correspondant à la demande."/>
        </div>
      </div>
    </div>
    <div v-else>
      <a-alert
          message="Il faut une licence ULTIMATE pour utiliser l'analyse par profil de côte."
          type="info" show-icon class="m-1"/>
    </div>
  </div>
</template>

<style scoped>

</style>