<template>
  <div id="profiler-filter-pane" v-if="filters">
    <!--<profiler-filter-pane-header/>-->

    <div class="profiler-filters">
      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Cote 1X2</legend>
        <div class="filter-row">
          <label class="flex-grow">Victoire domicile</label>
          <a-input size="small" v-model:value="filters.homeWinOdd"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeWinOddMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('homeWinOdd', margin)">{{
                    (margin * 100.0).toFixed(1)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeWinOddActive" @change="_onValueChange" size="small" class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Match nul</label>
          <a-input size="small" v-model:value="filters.drawOdd"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.drawOddMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('drawOdd', margin)">{{
                    (margin * 100.0).toFixed(1)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.drawOddActive" @change="_onValueChange" size="small" class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Victoire à l'extérieur</label>
          <a-input size="small" v-model:value="filters.awayWinOdd"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayWinOddMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('awayWinOdd', margin)">{{
                    (margin * 100.0).toFixed(1)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayWinOddActive" @change="_onValueChange" size="small" class="mt-1 ml-1"/>
        </div>

      </fieldset>

      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Probabilité 1X2</legend>
        <div class="filter-row">
          <label class="flex-grow">Victoire domicile</label>
          <a-input size="small" v-model:value="filters.homeWinProbability"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ filters.homeWinProbabilityMargin * 100.0 }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('homeWinProbability', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeWinProbabilityActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Match nul</label>
          <a-input size="small" v-model:value="filters.drawProbability"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.drawProbabilityMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('drawProbability', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.drawProbabilityActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Victoire extérieure</label>
          <a-input size="small" v-model:value="filters.awayWinProbability"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayWinProbabilityMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('awayWinProbability', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayWinProbabilityActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>

      </fieldset>

      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Cotes BTTS</legend>
        <div class="filter-row">
          <label class="flex-grow">BTTS - Oui</label>
          <a-input size="small" v-model:value="filters.bttsYesOdd"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.bttsYesOddMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('bttsYesOdd', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.bttsYesOddActive" @change="_onValueChange" size="small" class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">BTTS - Non</label>
          <a-input size="small" v-model:value="filters.bttsNoOdd"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.bttsNoOddMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('bttsNoOdd', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.bttsNoOddActive" @change="_onValueChange" size="small" class="mt-1 ml-1"/>
        </div>
      </fieldset>

      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Probabilité BTTS</legend>
        <div class="filter-row">
          <label class="flex-grow">BTTS - Oui</label>
          <a-input size="small" v-model:value="filters.bttsYesProbability"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.bttsYesProbabilityMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('bttsYesProbability', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.bttsYesProbabilityActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">BTTS - Non</label>
          <a-input size="small" v-model:value="filters.bttsNoProbability"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.bttsNoProbabilityMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('bttsNoProbability', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.bttsNoProbabilityActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
      </fieldset>

      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Cotes U/O 2.5</legend>
        <div class="filter-row">
          <label class="flex-grow">Moins de 2.5 buts</label>
          <a-input size="small" v-model:value="filters.under25Odd"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.under25OddMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('under25Odd', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.under25OddActive" @change="_onValueChange" size="small" class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Plus de 2.5 buts</label>
          <a-input size="small" v-model:value="filters.over25Odd"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.over25OddMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('over25Odd', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.over25OddActive" @change="_onValueChange" size="small" class="mt-1 ml-1"/>
        </div>
      </fieldset>

      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Probabilité U/O 2.5</legend>
        <div class="filter-row">
          <label class="flex-grow">Moins de 2.5 buts</label>
          <a-input size="small" v-model:value="filters.under25Probability"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.under25ProbabilityMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('under25Probability', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.under25ProbabilityActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Plus de 2.5 buts</label>
          <a-input size="small" v-model:value="filters.over25Probability"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.over25ProbabilityMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('over25Probability', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.over25ProbabilityActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
      </fieldset>

      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Taux BTTS</legend>
        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / season</label>
          <a-input size="small" v-model:value="filters.homeBttsRateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeBttsRateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('homeBttsRateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeBttsRateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / saison à domicile</label>
          <a-input size="small" v-model:value="filters.homeBttsRateSeasonAtHome"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeBttsRateSeasonAtHomeMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('homeBttsRateSeasonAtHome', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeBttsRateSeasonAtHomeActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / season</label>
          <a-input size="small" v-model:value="filters.awayBttsRateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayBttsRateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('awayBttsRateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayBttsRateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / saison à extérieur</label>
          <a-input size="small" v-model:value="filters.awayBttsRateSeasonAtAway"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayBttsRateSeasonAtAwayMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('awayBttsRateSeasonAtAway', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayBttsRateSeasonAtAwayActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
      </fieldset>

      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Taux de victoire</legend>
        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / season</label>
          <a-input size="small" v-model:value="filters.homeWinRateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeWinRateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('homeWinRateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeWinRateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / saison à domicile</label>
          <a-input size="small" v-model:value="filters.homeWinRateSeasonAtHome"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeWinRateSeasonAtHomeMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('homeWinRateSeasonAtHome', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeWinRateSeasonAtHomeActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / season</label>
          <a-input size="small" v-model:value="filters.awayWinRateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayWinRateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('awayWinRateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayWinRateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / saison à extérieur</label>
          <a-input size="small" v-model:value="filters.awayWinRateSeasonAtAway"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayWinRateSeasonAtAwayMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('awayWinRateSeasonAtAway', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayWinRateSeasonAtAwayActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
      </fieldset>

      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Taux de défaite</legend>
        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / season</label>
          <a-input size="small" v-model:value="filters.homeLoseRateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeLoseRateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('homeLoseRateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeLoseRateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / saison à domicile</label>
          <a-input size="small" v-model:value="filters.homeLoseRateSeasonAtHome"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeLoseRateSeasonAtHomeMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('homeLoseRateSeasonAtHome', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeLoseRateSeasonAtHomeActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / season</label>
          <a-input size="small" v-model:value="filters.awayLoseRateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayLoseRateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('awayLoseRateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayLoseRateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / saison à extérieur</label>
          <a-input size="small" v-model:value="filters.awayLoseRateSeasonAtAway"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayLoseRateSeasonAtAwayMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('awayLoseRateSeasonAtAway', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayLoseRateSeasonAtAwayActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
      </fieldset>

      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Taux de Over 0.5</legend>

        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / saison à domicile</label>
          <a-input size="small" v-model:value="filters.homeOver05RateSeasonAtHome"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeOver05RateSeasonAtHomeMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('homeOver05RateSeasonAtHome', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeOver05RateSeasonAtHomeActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / season</label>
          <a-input size="small" v-model:value="filters.homeOver05RateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeOver05RateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('homeOver05RateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeOver05RateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / season</label>
          <a-input size="small" v-model:value="filters.awayOver05RateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayOver05RateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('awayOver05RateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayOver05RateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / saison à extérieur</label>
          <a-input size="small" v-model:value="filters.awayOver05RateSeasonAtAway"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayOver05RateSeasonAtAwayMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('awayOver05RateSeasonAtAway', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayOver05RateSeasonAtAwayActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
      </fieldset>

      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Taux de Over 1.5</legend>

        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / saison à domicile</label>
          <a-input size="small" v-model:value="filters.homeOver15RateSeasonAtHome"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeOver15RateSeasonAtHomeMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('homeOver15RateSeasonAtHome', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeOver15RateSeasonAtHomeActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / season</label>
          <a-input size="small" v-model:value="filters.homeOver15RateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeOver15RateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('homeOver15RateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeOver15RateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / season</label>
          <a-input size="small" v-model:value="filters.awayOver15RateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayOver15RateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('awayOver15RateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayOver15RateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / saison à extérieur</label>
          <a-input size="small" v-model:value="filters.awayOver15RateSeasonAtAway"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayOver15RateSeasonAtAwayMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('awayOver15RateSeasonAtAway', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayOver15RateSeasonAtAwayActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
      </fieldset>

      <fieldset class="fieldset-bloc mx-1 mt-2 text-left">
        <legend>Taux de Over 2.5</legend>

        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / saison à domicile</label>
          <a-input size="small" v-model:value="filters.homeOver25RateSeasonAtHome"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeOver25RateSeasonAtHomeMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('homeOver25RateSeasonAtHome', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeOver25RateSeasonAtHomeActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe dom. / season</label>
          <a-input size="small" v-model:value="filters.homeOver25RateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.homeOver25RateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('homeOver25RateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.homeOver25RateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / season</label>
          <a-input size="small" v-model:value="filters.awayOver25RateSeason"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayOver25RateSeasonMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions" @click="_setMarginValue('awayOver25RateSeason', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayOver25RateSeasonActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
        <hr style="margin: 2px 0 2px 0"/>
        <div class="filter-row">
          <label class="flex-grow">Equipe ext. / saison à extérieur</label>
          <a-input size="small" v-model:value="filters.awayOver25RateSeasonAtAway"
                   style="width: 32px" @keyup="_onValueChange"/>
          <a-dropdown :trigger="['click']" class="flex-grow-0 ml-1">
            <button class="info small" @click.prevent>
              {{ (filters.awayOver25RateSeasonAtAwayMargin * 100.0).toFixed(1) }}%
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="margin in _marginOptions"
                             @click="_setMarginValue('awayOver25RateSeasonAtAway', margin)">{{
                    (margin * 100.0).toFixed(2)
                  }}%
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-switch v-model:checked="filters.awayOver25RateSeasonAtAwayActive" @change="_onValueChange" size="small"
                    class="mt-1 ml-1"/>
        </div>
      </fieldset>
    </div>
  </div>

</template>
<script>

import ProfilerFilterPaneHeader from "@/components/routes/profiler/panes/filter/ProfilerFilterPaneHeader.vue";
import {profilerRouteStore} from "@/components/routes/profiler/ProfilerRoute.store";

export default {
  name: "ProfilerFilterPane",
  components: {ProfilerFilterPaneHeader},

  mounted() {
    profilerRouteStore().applyFilters(this.filters)
  },

  data() {
    return {
      filters: {
        homeWinOdd: null,
        homeWinOddMargin: 0.025,
        homeWinOddActive: false,
        drawOdd: null,
        drawOddMargin: 0.025,
        drawOddActive: false,
        awayWinOdd: null,
        awayWinOddMargin: 0.025,
        awayWinOddActive: false,

        homeWinProbability: null,
        homeWinProbabilityMargin: 0.025,
        homeWinProbabilityActive: false,
        drawProbability: null,
        drawProbabilityMargin: 0.025,
        drawProbabilityActive: false,
        awayWinProbability: null,
        awayWinProbabilityMargin: 0.025,
        awayWinProbabilityActive: false,

        under25Odd: null,
        under25OddMargin: 0.025,
        under25OddActive: false,
        over25Odd: null,
        over25OddMargin: 0.025,
        over25OddActive: false,
        under25Probability: null,
        under25ProbabilityMargin: 0.025,
        under25ProbabilityActive: false,
        over25Probability: null,
        over25ProbabilityMargin: 0.025,
        over25ProbabilityActive: false,

        bttsYesOdd: null,
        bttsYesOddMargin: 0.025,
        bttsYesOddActive: false,
        bttsNoOdd: null,
        bttsNoOddMargin: 0.025,
        bttsNoOddActive: false,
        bttsYesProbability: null,
        bttsYesProbabilityMargin: 0.025,
        bttsYesProbabilityActive: false,
        bttsNoProbability: null,
        bttsNoProbabilityMargin: 0.025,
        bttsNoProbabilityActive: false,

        homeScoredGoalsL5AtHome: null,
        homeScoredGoalsL5AtHomeMargin: 0.025,
        homeScoredGoalsL5AtHomeActive: false,
        homeConcededGoalsL5AtHome: null,
        homeConcededGoalsL5AtHomeMargin: 0.025,
        homeConcededGoalsL5AtHomeActive: false,
        homeScoredGoalsL10: null,
        homeScoredGoalsL10Margin: 0.025,
        homeScoredGoalsL10Active: false,
        homeConcededGoalsL10: null,
        homeConcededGoalsL10Margin: 0.025,
        homeConcededGoalsL10Active: false,
        homeBttsRateSeason: null,
        homeBttsRateSeasonMargin: 0.025,
        homeBttsRateSeasonActive: false,
        homeBttsRateSeasonAtHome: null,
        homeBttsRateSeasonAtHomeMargin: 0.025,
        homeBttsRateSeasonAtHomeActive: false,
        homeWinRateSeason: null,
        homeWinRateSeasonMargin: 0.025,
        homeWinRateSeasonActive: false,
        homeWinRateSeasonAtHome: null,
        homeWinRateSeasonAtHomeMargin: 0.025,
        homeWinRateSeasonAtHomeActive: false,
        homeLoseRateSeason: null,
        homeLoseRateSeasonMargin: 0.025,
        homeLoseRateSeasonActive: false,
        homeLoseRateSeasonAtHome: null,
        homeLoseRateSeasonAtHomeMargin: 0.025,
        homeLoseRateSeasonAtHomeActive: false,
        homeOver05RateSeason: null,
        homeOver05RateSeasonMargin: 0.025,
        homeOver05RateSeasonActive: false,
        homeOver15RateSeason: null,
        homeOver15RateSeasonMargin: 0.025,
        homeOver15RateSeasonActive: false,
        homeOver25RateSeason: null,
        homeOver25RateSeasonMargin: 0.025,
        homeOver25RateSeasonActive: false,
        homeOver05RateSeasonAtHome: null,
        homeOver05RateSeasonAtHomeMargin: 0.025,
        homeOver05RateSeasonAtHomeActive: false,
        homeOver15RateSeasonAtHome: null,
        homeOver15RateSeasonAtHomeMargin: 0.025,
        homeOver15RateSeasonAtHomeActive: false,
        homeOver25RateSeasonAtHome: null,
        homeOver25RateSeasonAtHomeMargin: 0.025,
        homeOver25RateSeasonAtHomeActive: false,

        awayScoredGoalsL5AtAway: null,
        awayScoredGoalsL5AtAwayMargin: 0.025,
        awayScoredGoalsL5AtAwayActive: false,
        awayConcededGoalsL5AtAway: null,
        awayConcededGoalsL5AtAwayMargin: 0.025,
        awayConcededGoalsL5AtAwayActive: false,
        awayScoredGoalsL10: null,
        awayScoredGoalsL10Margin: 0.025,
        awayScoredGoalsL10Active: false,
        awayConcededGoalsL10: null,
        awayConcededGoalsL10Margin: 0.025,
        awayConcededGoalsL10Active: false,
        awayBttsRateSeason: null,
        awayBttsRateSeasonMargin: 0.025,
        awayBttsRateSeasonActive: false,
        awayBttsRateSeasonAtAway: null,
        awayBttsRateSeasonAtAwayMargin: 0.025,
        awayBttsRateSeasonAtAwayActive: false,
        awayWinRateSeason: null,
        awayWinRateSeasonMargin: 0.025,
        awayWinRateSeasonActive: false,
        awayWinRateSeasonAtAway: null,
        awayWinRateSeasonAtAwayMargin: 0.025,
        awayWinRateSeasonAtAwayActive: false,
        awayLoseRateSeason: null,
        awayLoseRateSeasonMargin: 0.025,
        awayLoseRateSeasonActive: false,
        awayLoseRateSeasonAtAway: null,
        awayLoseRateSeasonAtAwayMargin: 0.025,
        awayLoseRateSeasonAtAwayActive: false,
        awayOver05RateSeason: null,
        awayOver05RateSeasonMargin: 0.025,
        awayOver05RateSeasonActive: false,
        awayOver15RateSeason: null,
        awayOver15RateSeasonMargin: 0.025,
        awayOver15RateSeasonActive: false,
        awayOver25RateSeason: null,
        awayOver25RateSeasonMargin: 0.025,
        awayOver25RateSeasonActive: false,
        awayOver05RateSeasonAtAway: null,
        awayOver05RateSeasonAtAwayMargin: 0.025,
        awayOver05RateSeasonAtAwayActive: false,
        awayOver15RateSeasonAtAway: null,
        awayOver15RateSeasonAtAwayMargin: 0.025,
        awayOver15RateSeasonAtAwayActive: false,
        awayOver25RateSeasonAtAway: null,
        awayOver25RateSeasonAtAwayMargin: 0.025,
        awayOver25RateSeasonAtAwayActive: false,
      }
    }
  },

  methods: {
    _probaToOdd(proba) {
      return (proba === 0) ? '∞' : (1 / proba).toFixed(2)
    },
    _setMarginValue(field, value) {
      console.log('setMarginValue', field, value, parseFloat(value))
      this.filters[field + 'Margin'] = parseFloat(value)
      this._onValueChange()
    },
    _onValueChange() {
      profilerRouteStore().applyFilters(this.filters)
    }
  },
  computed: {
    _marginOptions() {
      // return all value between 0.01 and 0.03 by 0.0025
      const results = []
      for (let margin = 0.00; margin <= 0.20; margin += 0.005) {
        // round to 4 decimals, to avoid floating point issues
        results.push(Math.round(margin * 10000) / 10000)
      }
      return results
    }
  },
  watch: {}
}
</script>
<style lang="less">
@import "@/assets/styles/variables.less";

#profiler-filter-pane {
  margin: @padding-small 0 @padding-small @padding-small;
  border-radius: @radius-regular;
  background: rgb(94, 53, 43);
  background: linear-gradient(122deg, rgba(94, 53, 43, 1) 0%, rgba(94, 53, 43, 0.95) 3%, rgba(37, 37, 42, 0.85) 21%, rgba(37, 37, 42, 0.75) 100%);
  height: calc(100dvh - @toolbar-regular);
  width: 400px !important;
  min-width: 400px !important;

  .filter-row {
    display: flex;
    line-height: 24px;

    label {
      font-size: 9pt;
    }
  }

  .profiler-filters {
    //height: calc(100dvh - 140px);
    height: calc(100dvh - 40px);
    overflow-y: scroll;
    scrollbar-width: none;
  }
}

.mobile {
  #player-pane {
    width: calc(100vw - @padding-small) !important;
    min-width: calc(100vw - @padding-small) !important;
  }
}

</style>