<script>

import {profilerRouteStore} from "@/components/routes/profiler/ProfilerRoute.store";

export default {
  name: "ProfilerContinuousValueWidget",
  components: {

  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },


  mounted() {

  },

  computed: {
    _property(){
      if(!this.item) return null
      return this.item.property
    },
    _data(){
      if(!profilerRouteStore().data) return null
      return profilerRouteStore().data[this._property + 'Data'];
    },
    _maxValue(){
      if(!this._data) return 1
      return this._data.maxValue
    },
    _maxValueFormatted(){
      if(!this._data) return '-'
      return (this._data.maxValue * 100.0).toFixed(2)
    },
    _minValue(){
      if(!this._data) return 0
      return this._data.minValue
    },
    _minValueFormatted(){
      if(!this._data) return '-'
      return (this._data.minValue * 100.0).toFixed(2)
    },
    _startValue(){
      if(!this._data) return 0
      return this._data.startValue
    },
    _endValue(){
      if(!this._data) return 0
      return this._data.endValue
    },

    _coefAmplification(){
      if(!this._data) return 1
      return 1.00 / this._data.maxValue
    },
    _values(){
      const values = []
      if(!this._data) return values
      for(let i = 0; i < 100; i++){
        let p = this._data.map[i] * this._coefAmplification
        values.push({
          value: i,
          percentage: (p * 100) + '%',
          antiPercentage: ((1-p) * 100) + '%',
        })
      }
      return values
    },
    _title(){
      return profilerRouteStore().getWidgetTitle(this._property)
    },
    _filters(){
      return profilerRouteStore().filters
    },
    _targetedValue(){
      if (!this.item) return null;
      if (!this._property) return null;
      if (!this._filters) return null;

      // get property in filters object
      const property = this._filters[this._property];
      if (!property) return null;

      return parseFloat(property);
    },
    _targetedMargin(){
      if (!this.item) return null;
      if (!this._property) return null;
      if (!this._filters) return null;

      // get property in filters object
      const property = this._filters[this._property + 'Margin'];
      if (!property) return null;

      return parseFloat(property);
    },

    _targetedValueMin(){
      if(!this._targetedValue) return null
      if(!this._targetedMargin) return null
      const value = this._targetedValue - this._targetedValue * this._targetedMargin;
      if(!value) return null
      // round with 2 decimals
      return value
    },

    _targetedValueMax(){
      if(!this._targetedValue) return null
      if(!this._targetedMargin) return null
      const value = this._targetedValue + this._targetedValue * this._targetedMargin;
      if(!value) return null
      // round with 2 decimals
      return value
    },

    _intervalSize(){
      return (this._endValue - this._startValue) / 8.0
    }
  },

  methods: {
    _squareClasses(v) {
      if (!this._targetedValue) return null
      if (!this._targetedMargin) return null
      if (!v) return null
      if (!v.value) return null

      const intervalMin = this._startValue + (v.value - 1) * (this._endValue - this._startValue) / 100.0
      const intervalMax = this._startValue + (v.value + 0) * (this._endValue - this._startValue) / 100.0

      const isTargetInterval = this._targetedValue >= intervalMin && this._targetedValue <= intervalMax
      if(isTargetInterval){
        console.log(v.value, intervalMin, intervalMax, this._targetedValueMin, this._targetedValueMax)
      }

      const isInMargin = !isTargetInterval && (intervalMin >= this._targetedValueMin && intervalMax <= this._targetedValueMax)


      const classes = {
        'target-margin': isInMargin,
        target: isTargetInterval,
      }
      //console.log(classes)
      return classes
    },
    _intervalValue(i) {
      return (this._startValue + i * this._intervalSize).toFixed(2)
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      {{ _title }} {{_targetedValue}} ± {{(_targetedMargin * 100.0).toFixed(1)}}%
    </div>
    <div class="widget-content">
      <table>
        <tr>
          <td style="font-size: 6pt; width: 16px">{{_maxValueFormatted}}</td>
          <td rowspan="5" colspan="10">
            <div class="flex">
              <div v-for="v in _values" class="bar">
                <div class="bar-anti-value" :style="{'height' : v.antiPercentage}"/>
                <div class="bar-value" :style="{'height' : v.percentage}"/>
              </div>
            </div>
            <div class="flex mt-1">
              <div v-for="v in _values" class="square" :class="_squareClasses(v)">
              </div>
            </div>

          </td>
        </tr>
        <tr>
          <td  style="font-size: 6pt; width: 16px">%.Max</td>
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr>
          <td  style="font-size: 6pt; width: 16px">%.Min</td>
        </tr>
        <tr>
          <td style="font-size: 6pt">{{_minValueFormatted}}</td>
        </tr>
        <tr style="font-size: 6pt">
          <td>%</td>
          <td>{{_intervalValue(0)}}</td>
          <td>{{_intervalValue(1)}}</td>
          <td>{{_intervalValue(2)}}</td>
          <td>{{_intervalValue(3)}}</td>
          <td>{{_intervalValue(4)}}</td>
          <td>{{_intervalValue(5)}}</td>
          <td>{{_intervalValue(6)}}</td>
          <td>{{_intervalValue(7)}}</td>
          <td>{{_endValue}}</td>
        </tr>
      </table>

    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.widget-content {
  height: calc(100% - 28px);
}

.square{
  height: 4px;
  background-color: @background-color;
  flex-grow: 1;

  &.target{
    background-color: @primary-color;
  }
  &.target-margin{
    background-color: @tertiary-color;
  }
}

.bar{
  height: 107px;
  flex-grow: 1;

  .bar-value{
    background-color: @primary-color;
    width: 100%;
  }
}

</style>