<script>

import {selectionStore} from "@/stores/Selection.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import {modalStore} from "@/stores/Modal.store";
import {monitoringStore} from "@/stores/Monitoring.store";
import {bankrollStore} from "@/stores/Bankroll.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "SelectionPaneHeader",
  components: {FontAwesomeIcon},
  computed: {

    _selectionActive() {
      let selectedSelectionUID = selectionPaneStore().getSelectionActiveUIDBySport(contextStore().getSport);
      return selectionStore().get(selectedSelectionUID);
    },
    _selectionActiveName() {
      let selectionActive = this._selectionActive;
      if (!selectionActive) return "Choisir une sélection";

      // Keep only the first 20 characters
      if (selectionActive.name.length > 25) {
        return selectionActive.name.substring(0, 25) + '...'
      }

      return selectionActive.name
    },
    _selectionActiveMonitoringWaitingBetsCount() {
      let selectionActive = this._selectionActive;
      if (!selectionActive) return null;
      const allMonitorings = monitoringStore().getBySelectionUID(selectionActive.uid)
      let betCount = 0
      allMonitorings.forEach(m => {
        const mBk = bankrollStore().get(m.bankrollUID)
        if (mBk) {
          betCount += mBk.ticketWaitCount
        }
      })
      return betCount
    }
  },
  methods: {
    _onClickSelection() {
      modalStore().openModal({uid: "selectionsModal", payload: {type: "GAME"}})
    },
    _clearSelection() {
      selectionPaneStore().clearSelection()
      selectionPaneStore().runSelection()
    },
    _openMonitoringList() {
      if(!this._selectionActive) return
      modalStore().openModal({
        uid: "selectionMonitoringListModal",
        payload: {selectionUID: this._selectionActive.uid}
      })
    },
    _showAllMonitoringTicketsModal() {
      modalStore().openModal({uid: "allMonitoringTicketsModal"})
    }
  }
}
</script>

<template>
  <header id="selection-pane-header">
    <div class="flex pt-1 mx-1">
      <button class="primary square mr-1" @click="_showAllMonitoringTicketsModal">
        <font-awesome-icon :icon="['fas', 'bolt']"/>
      </button>
      <button class="accent" @click="_onClickSelection">
        {{ _selectionActiveName }}
      </button>
      <a-tooltip title="Voir les monitoring" placement="right" v-if="_selectionActive">
        <a-badge :count="_selectionActiveMonitoringWaitingBetsCount">
          <button class="ml-1 info" @click="_openMonitoringList()">
            <font-awesome-icon :icon="['fas', 'heart-pulse']"/>
          </button>
        </a-badge>
      </a-tooltip>
      <div class="flex-grow"/>
      <button class="info" v-if="_selectionActive" @click="_clearSelection">
        <font-awesome-icon icon="fa-solid fa-close"/>
      </button>
    </div>
  </header>
</template>