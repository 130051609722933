<script>

import ProfilerAnalysePerspective
  from "@/components/routes/profiler/panes/analyse/perspective/ProfilerAnalysePerspective.vue";

export default {
  name: "ProfilerAnalysePane",
  components: {
    ProfilerAnalysePerspective

  },
}
</script>

<template>
  <div id="profiler-analyse-pane-wrapper">
    <div id="profiler-analyse-pane">
      <profiler-analyse-perspective/>
    </div>
  </div>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";

#profiler-analyse-pane {
  margin: @padding-small;
  border-radius: @radius-regular;
  background: rgb(34, 34, 34);
  background: linear-gradient(215deg, rgba(255, 255, 255, 0.05) 0%, rgba(44, 44, 46, 0.80) 30%, rgba(44, 44, 46, 0.80) 40%, rgba(34, 34, 34, 0.90) 100%);
  height: calc(100dvh - @toolbar-regular);
}
</style>