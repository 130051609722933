<script>
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";
import SelectionFavIndicator from "@/components/common/SelectionFavIndicator.vue";
import {colorStore} from "@/stores/Color.store";

export default {
  name: "LeagueGroupsModal",
  components: {SelectionFavIndicator},
  data() {
    return {}
  },
  mounted() {
  },
  computed: {
    _modal() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return null;
      if (visibleModal.uid !== 'leagueGroupsModal') return null
      return visibleModal;
    },
    _isVisible() {
      if (!this._modal) return false;
      return this._modal.uid === 'leagueGroupsModal';
    },
    _payload() {
      if (!this._modal || !this._modal.payload) return null;
      return this._modal.payload;
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _groups() {
      return leagueGroupsStore().getBySport(contextStore().getSport);
    },
    _sport() {
      return contextStore().getSport
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal()
    },
    _firstLetter(g) {
      if (!g) return '?';
      return g.name.charAt(0).toUpperCase()
    },
    _selectionStyle(g) {
      let bgColor = colorStore().get(JSON.stringify(g));
      return {
        backgroundColor: bgColor,
        color: colorStore().getContrastColor(bgColor)
      }
    },
    _openLeagueGroupModal(g) {
      modalStore().openModal({
        uid: "leagueGroupModal",
        payload: {
          sport: g.sport,
          group: g
        }
      })
    },
    _openNewLeagueGroupModal() {
      modalStore().openModal({
        uid: "leagueGroupModal",
        payload: {
          sport: contextStore().getSport,
          group: null
        }
      })
    },
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header pl-2">
      Groupes de compétition - {{ _sport }}
    </header>

    <div class="content">
      <a-alert v-if="_groups.length === 0" message="Aucun groupe de compétition n'a été créé pour ce sport" type="info" show-icon class="m-1"/>
      <div v-for="g in _groups" :class="{'league-group-item': true}" @click="_openLeagueGroupModal(g)">
        <div class="league-group-icon" :style="_selectionStyle(g)">
          {{ _firstLetter(g) }}
        </div>
        <div class="league-group-texts">
          <div class="flex">
            <label>{{ g.name }} </label>
          </div>
        </div>
      </div>
    </div>


    <template #footer>
      <div class="flex m-1">
        <button class="m-1 primary" @click="_openNewLeagueGroupModal">Nouveau groupe</button>
        <div class="flex-grow"></div>
        <button class="m-1 info" @click="_onCancel">Fermer</button>
      </div>
    </template>
  </AModal>
</template>


<style scoped lang="less">
@import "@/assets/styles/base.less";

.league-group-item {
  border-radius: 0;
  padding: @padding-regular;
  color: @text-color;
  cursor: pointer;
  text-align: left;
  display: flex;
  border: 1px solid transparent;
  border-top: 1px solid @stroke-color;

  &:first-child {
    border: none;
  }

  &:nth-child(even) {
    background: @background-color-dark;
  }

  .league-group-icon {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px;
    max-height: 24px;
    border-radius: @radius-small;
    background-color: @primary-color;
    display: inline-block;
    margin-right: 8px;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
  }

  .league-group-texts {

    flex-grow: 1;

    p {
      font-size: 11px !important;
      color: @text-color;
      margin: 0;
    }
  }

  &:hover {
    border: 1px solid @stroke-color;
  }
}
</style>