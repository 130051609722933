<script>

import Statistic from "@/components/common/Statistic.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameHour from "@/components/common/GameHour.vue";
import GameDate from "@/components/common/GameDate.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import {gameStore} from "@/stores/Game.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import LeagueName from "@/components/common/LeagueName.vue";
import GameFavIndicator from "@/components/common/GameFavIndicator.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import GoalsTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Goals.Tab.vue";
import DiagnosticTab
  from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Diagnostic.Tab.vue";
import StatsTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Stats.Tab.vue";
import FormTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Forme.Tab.vue";
import UnderOverTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.UnderOver.Tab.vue";
import H2HTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.H2H.Tab.vue";
import ScoreTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Score.Tab.vue";
import SimilarTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Similar.Tab.vue";
import StandingTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Standing.Tab.vue";

export default {
  name: "SelectionPaneItemTabs",
  components: {
    ScoreTab, H2HTab, UnderOverTab, FormTab, DiagnosticTab, StatsTab, GoalsTab, SimilarTab, StandingTab,
    LeagueLogo, GameFavIndicator, LeagueName,
    FontAwesomeIcon,
    OfferChoiceButton,
    TeamLogo,
    GameDate,
    GameHour,
    AreaFlag,
    TeamName,
    Statistic,
  },
  props: {
    gameUid: {
      type: Number,
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {
    _game() {
      if (!this.gameUid) return null
      return gameStore().get(this.gameUid)
    }
  },

  methods: {
    _isTabVisible(tab) {
      if (!this._game) {
        return false  // Pas de game, pas de tab
      }
      const sport = this._game.sport
      switch (sport) {
        case 'FOOTBALL':
          return true
        case 'HOCKEY':
          return !['STANDING'].includes(tab)
        case 'TENNIS':
          return ['FORME', 'H2H'].includes(tab)
        default:
          return !['SCORE', 'UNDER', 'OVER'].includes(tab)
      }
    },

    _isSelectedTab(tab) {
      if (!this._isTabVisible(tab)) {
        return false
      }
      return selectionPaneStore().selectedItemTab === tab
    },
    
    _setSelectedTab(tab) {
      selectionPaneStore().selectedItemTab = tab
    },
  }
}
</script>

<template>
  <div>
    <div class="flex">
      <button
          v-if="_isTabVisible('FORME')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('FORME')}"
          @click="_setSelectedTab('FORME')">Forme
      </button>
      <button
          v-if="_isTabVisible('STANDING')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('STANDING')}"
          @click="_setSelectedTab('STANDING')">Class
      </button>
      <button
          v-if="_isTabVisible('SCORE')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('SCORE')}"
          @click="_setSelectedTab('SCORE')">Score
      </button>
      <button
          v-if="_isTabVisible('GOALS')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('GOALS')}"
          @click="_setSelectedTab('GOALS')">Pts
      </button>
      <button
          v-if="_isTabVisible('OVER_UNDER')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('OVER_UNDER')}"
          @click="_setSelectedTab('OVER_UNDER')">O/U
      </button>
      <button
          v-if="_isTabVisible('H2H')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('H2H')}"
          @click="_setSelectedTab('H2H')">H2H
      </button>
      <button
          v-if="_isTabVisible('SIM')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('SIM')}"
          @click="_setSelectedTab('SIM')">Sim
      </button>
      <button
          v-if="_isTabVisible('DIAGNOSTIC')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('DIAGNOSTIC')}"
          @click="_setSelectedTab('DIAGNOSTIC')">Diag
      </button>
      <button
          v-if="_isTabVisible('STATS')"
          :class="{'small no-radius tab': true, 'selected' : _isSelectedTab('STATS')}"
          @click="_setSelectedTab('STATS')">Stats
      </button>
    </div>
    <hr>
    <div>
      <form-tab :game-uid="gameUid" v-if="_isSelectedTab('FORME')"/>
      <standing-tab :game-uid="gameUid" v-if="_isSelectedTab('STANDING')"/>
      <goals-tab :game-uid="gameUid" v-if="_isSelectedTab('GOALS')"/>
      <under-over-tab :game-uid="gameUid" v-if="_isSelectedTab('OVER_UNDER')"/>
      <score-tab :game-uid="gameUid" v-if="_isSelectedTab('SCORE')"/>
      <h2-h-tab :game-uid="gameUid" v-if="_isSelectedTab('H2H')"/>
      <similar-tab :game-uid="gameUid" v-if="_isSelectedTab('SIM')"/>
      <diagnostic-tab :game-uid="gameUid" v-if="_isSelectedTab('DIAGNOSTIC')"/>
      <stats-tab :game-uid="gameUid" v-if="_isSelectedTab('STATS')"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.line-game-data {
  border-top: 1px solid @stroke-color;
  background: @background-color;
  font-size: 8pt;
  color: @text-color-dark;
  display: flex;
  padding: @padding-tiny 0;

  .item-game-date {
    flex-grow: 100;
    text-align: center;
    max-width: 20%;
  }

  .item-game-venue {
    flex-grow: 100;
    max-width: 40%;
    text-align: left;
    overflow: hidden;
  }

  .item-game-weather {
    flex-grow: 100;
    max-width: 40%;
    text-align: right;
  }
}
</style>