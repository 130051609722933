<script>
import axiosService from "@/services/axios.service";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import GameDate from "@/components/common/GameDate.vue";
import GameScore from "@/components/common/GameScore.vue";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import IndicateurCircle from "@/components/common/IndicateurCircle.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import YoutubeButton from "@/components/common/YoutubeButton.vue";
import {sportStore} from "@/stores/Sport.store";

export default {
  name: "SelectionPaneItem.H2H.Tab",
  components: {YoutubeButton, IndicateurCircle, GameResultCircle, GameScore, GameDate, TeamLogo},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {

      filters: {
        type: 'ALL',
        moment: 'FT',
        limit: {value: 1000, label: 'Tous', shortLabel: 'Tous'}
      },

      limitChoices: [
        {value: 3, label: '3 derniers', shortLabel: 'L3'},
        {value: 5, label: '5 derniers', shortLabel: 'L5'},
        {value: 6, label: '6 derniers', shortLabel: 'L6'},
        {value: 10, label: '10 derniers', shortLabel: 'L10'},
        {value: 1000, label: 'Tous', shortLabel: 'Tous'},
      ],

      data: null
    }
  },

  beforeMount() {

    // On va chercher la préférence du type
    let type = preferencesStore().getFirstValue('SELECTION_H2H_TAB', 'TYPE');
    if (type) {
      this.filters.type = type
    } else {
      preferencesStore().save('SELECTION_H2H_TAB', 'TYPE', [this.filters.type])
    }

    const payload = {
      uid: this.gameUid,
    }
    axiosService.put('/dta/game/basic-data/h2h', payload).then(response => {
      this.data = response.data
    })
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid)
    },
    _gameHomeTeamUID() {
      if (!this._game) return null
      return this._game.homeTeamUID
    },
    _gameAwayTeamUID() {
      if (!this._game) return null
      return this._game.awayTeamUID
    },
    _games() {
      if (!this.data) return []
      if (!this.data.results) return []
      const results = []
      this.data.results.forEach(uid => {
        let game = gameStore().get(uid);
        if (!game) return
        results.push(game)
      })

      // sort by date desc
      results.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })

      return results
    },
    _filteredGames() {
      if (!this._games) return []
      if (this.filters.type === 'ALL') {
        return this._games.slice(0, this.filters.limit.value)
      }

      const results = []
      for (const game of this._games) {
        if (game.homeTeamUID === this._game.homeTeamUID) {
          results.push(game)
        }
      }

      // On limite le nombre de matchs
      return results.slice(0, this.filters.limit.value)
    },
    _1x2() {

      let countGames = this._filteredGames.length;
      if (countGames === 0) return {
        '1': '-', // % victoire de l'équipe qui joue a domicile dans ce match
        'X': '-', // % match nul
        '2': '-'  // % victoire de l'équipe qui joue a l'exterieur dans ce match
      }

      let count1 = 0.0
      let countX = 0.0
      let count2 = 0.0

      
      for (const game of this._filteredGames) {

        let homeScore = null
        let awayScore = null
        switch (this.filters.moment){
          case 'FT':
            homeScore = game.scoreFtHome
            awayScore = game.scoreFtAway
            break
          case 'P1':
            homeScore = game.scoreP1Home
            awayScore = game.scoreP1Away
            break
          case 'P2':
            homeScore = game.scoreP2Home
            awayScore = game.scoreP2Away
            break
          case 'P3':
            homeScore = game.scoreP3Home
            awayScore = game.scoreP3Away
            break
          case 'P4':
            homeScore = game.scoreP4Home
            awayScore = game.scoreP4Away
            break
        }

        if (homeScore > awayScore) {
          if (this._gameHomeTeamUID === game.homeTeamUID) {
            count1++
          } else {
            count2++
          }
        } else if (homeScore === awayScore) {
          countX++
        } else {
          if (this._gameHomeTeamUID === game.homeTeamUID) {
            count2++
          } else {
            count1++
          }
        }
      }

      const val1 = (count1 / countGames * 100.0).toFixed(0) + '%'
      const valX = (countX / countGames * 100.0).toFixed(0) + '%'
      const val2 = (count2 / countGames * 100.0).toFixed(0) + '%'

      return {
        '1': val1,
        'X': valX,
        '2': val2
      }
    },
    _btts() {

      if (this._filteredGames.length === 0) return '-'

      let count = 0.0
      for (const game of this._filteredGames) {
        let homeScore = null
        let awayScore = null
        switch (this.filters.moment){
          case 'FT':
            homeScore = game.scoreFtHome
            awayScore = game.scoreFtAway
            break
          case 'P1':
            homeScore = game.scoreP1Home
            awayScore = game.scoreP1Away
            break
          case 'P2':
            homeScore = game.scoreP2Home
            awayScore = game.scoreP2Away
            break
          case 'P3':
            homeScore = game.scoreP3Home
            awayScore = game.scoreP3Away
            break
          case 'P4':
            homeScore = game.scoreP4Home
            awayScore = game.scoreP4Away
            break
        }
        if (homeScore > 0 && awayScore > 0) {
          count++
        }
      }
      let value = count / this._filteredGames.length * 100.0;
      return value.toFixed(0) + '%'
    },
    _goals() {

      let countGames = this._filteredGames.length;
      if (countGames === 0) return {
        'all': '-', // moyenne de buts par match
        'home': '-',  // moyenne de buts par match de l'équipe qui joue a domicile
        'away': '-'   // moyenne de buts par match de l'équipe qui joue a l'exterieur
      }

      let countHome = 0.0
      let countAway = 0.0

      for (const game of this._filteredGames) {
        let homeScore = null
        let awayScore = null
        switch (this.filters.moment){
          case 'FT':
            homeScore = game.scoreFtHome
            awayScore = game.scoreFtAway
            break
          case 'P1':
            homeScore = game.scoreP1Home
            awayScore = game.scoreP1Away
            break
          case 'P2':
            homeScore = game.scoreP2Home
            awayScore = game.scoreP2Away
            break
          case 'P3':
            homeScore = game.scoreP3Home
            awayScore = game.scoreP3Away
            break
          case 'P4':
            homeScore = game.scoreP4Home
            awayScore = game.scoreP4Away
            break
        }

        if (this._gameHomeTeamUID === game.homeTeamUID) {
          countHome += homeScore
          countAway += awayScore
        } else {
          countHome += awayScore
          countAway += homeScore
        }
      }

      const valHome = (countHome / countGames).toFixed(1)
      const valAway = (countAway / countGames).toFixed(1)
      const valAll = ((countHome + countAway) / countGames).toFixed(1)

      return {
        'all': valAll,
        'home': valHome,
        'away': valAway
      }
    },
    _sport() {
      if (!this._game) return null
      return this._game.sport
    },
    _moments() {
      switch (this._sport) {
        case 'FOOTBALL':
          return ['FT', 'P1', 'P2']
        case 'BASKETBALL':
          return ['FT', 'P1', 'P2', 'P3', 'P4']
        case 'HANDBALL':
          return ['FT', 'P1', 'P2']
        case 'RUGBY':
          return ['FT', 'P1', 'P2']
        case 'HOCKEY':
          return ['FT', 'P1', 'P2', 'P3']
      }
    },
    _momentLabel() {
      return this.filters.moment
    },
    _pointName() {
      return sportStore().getPointName(this._sport)
    }
  },

  methods: {
    _indicateurCircleColor(game, location, moment) {
      const result = gameStore().getResultFor(game, location, moment)
      if (result === 0) return 'warning'
      if (result > 0) return 'positive'
      if (result < 0) return 'neutral'
    },
    _setType(type) {
      this.filters.type = type
      preferencesStore().save('SELECTION_H2H_TAB', 'TYPE', [this.filters.type])
    },
    _switchMoment(){
      const index = this._moments.indexOf(this.filters.moment)
      this.filters.moment = this._moments[(index + 1) % this._moments.length]

      preferencesStore().save('SELECTION_H2H_TAB', 'MOMENT_' + this._sport, [this.filters.moment])
    },
    _setLimit(limit) {
      this.filters.limit = limit
    }
  }
}
</script>

<template>
  <div>
    <div class="flex">
      <fieldset class="m-1  p-0">
        <legend class="m-0">Type</legend>
        <button :class="{'w-full small no-radius' : true, 'primary selected' : filters.type === 'ALL'}"
                style="height: 22.3px"
                @click="_setType('ALL')">Tous
        </button>
        <hr/>
        <button :class="{'w-full small no-radius' : true, 'primary selected' : filters.type === 'DOM_EXT'}"
                style="height: 22.3px"
                @click="_setType('DOM_EXT')">D/E
        </button>
      </fieldset>
      <fieldset class="m-1 text-left p-0">
        <legend class="m-0">Rés. ({{ _filteredGames.length }} matchs)</legend>
        <table>
          <tr>
            <th style="width: 40px">
              <team-logo size="small" :uid="_gameHomeTeamUID"/>
            </th>
            <th style="width: 40px">X</th>
            <th style="width: 40px">
              <team-logo size="small" :uid="_gameAwayTeamUID"/>
            </th>
          </tr>
          <tr>
            <td>{{ _1x2["1"] }}</td>
            <td>{{ _1x2["X"] }}</td>
            <td>{{ _1x2["2"] }}</td>
          </tr>
        </table>
      </fieldset>
      <fieldset class="m-1 text-left p-0">
        <legend class="m-0">{{ _pointName }}</legend>
        <table>
          <tr>
            <th>Tot</th>
            <th>
              <team-logo size="small" :uid="_gameHomeTeamUID"/>
            </th>
            <th>
              <team-logo size="small" :uid="_gameAwayTeamUID"/>
            </th>
            <th>BTTS</th>
          </tr>
          <tr>
            <td style="width: 32px">{{ _goals["all"] }}</td>
            <td style="width: 32px">{{ _goals["home"] }}</td>
            <td style="width: 32px">{{ _goals["away"] }}</td>
            <td style="width: 40px">{{ _btts }}</td>
          </tr>
        </table>
      </fieldset>
      <fieldset class="m-1 p-0">
        <legend class="m-0">Moment</legend>
        <button :class="{'small no-radius' : true}" style="width:46px; height: 46px"
                @click="_switchMoment">{{ _momentLabel }}
        </button>
      </fieldset>
    </div>
    <fieldset class="p-0 no-border mt-2 text-left">
      <div class="flex">
        <legend class="m-0 ml-2" style="height: 22.5px; line-height: 22.5px">Historique des tête-à-tête</legend>
        <div class="flex-grow"/>
        <a-dropdown :trigger="['click']">
          <button :class="{'small no-radius' : true}" style="height: 22.5px">
            ({{ filters.limit.shortLabel }})
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="limitChoice in limitChoices" @click="_setLimit(limitChoice)" class="text-left">
                {{ limitChoice.label }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

      </div>

      <div v-if="data">
        <a-alert v-if="_filteredGames.length === 0" type="info" show-icon message="Aucun match correspondant" class="m-1"/>
        <table v-else>
          <tr v-for="game in _filteredGames">
            <td>
              <game-date :uid="game.uid"/>
            </td>
            <td>
              <team-logo :uid="game.homeTeamUID" size="small"/>
            </td>
            <td>
            <span style="display: inline-block" class="mr-1">
              <indicateur-circle :type="_indicateurCircleColor(game, 'HOME', filters.moment)"/>
            </span>
              <game-score :uid="game.uid" style="display: inline-block" :moment="filters.moment"/>
              <span style="display: inline-block" class="ml-1">
              <indicateur-circle :type="_indicateurCircleColor(game, 'AWAY', filters.moment)"/>
            </span>
            </td>
            <td>
              <team-logo :uid="game.awayTeamUID" size="small"/>
            </td>
          </tr>
        </table>
      </div>
    </fieldset>
    <hr/>
    <div class="flex p-2">
      <p class="pr-2 m-0 flex-grow">💥 Que se passe-t-il quand ces deux équipes se retrouvent face à face ? ⚔️</p>
      <youtube-button video-id="ucGHUiOe9lo"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: @text-color;
  font-size: @font-size-small;

  tr {
    border-bottom: 1px solid @stroke-color;

    &:last-child {
      border-bottom: none;
    }

    th, td {
      padding: @padding-small;
      text-align: center;
      font-family: 'SourceCodePro', serif;
      font-weight: 400;
    }

    th {
      background-color: @background-color-light;
    }

    td {
      &.label {
        text-orientation: mixed;
        writing-mode: vertical-rl;

        &.ll {
          border-left: 1px solid @stroke-color;
        }
      }
    }
  }
}
</style>