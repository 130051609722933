<script>
import TeamLogo from "@/components/common/TeamLogo.vue";
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";
import axiosService from "@/services/axios.service";
import TeamName from "@/components/common/TeamName.vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import {statStore} from "@/stores/Statistic.store";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import LeagueName from "@/components/common/LeagueName.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import FrequencyStatsWidgetRow from "@/components/routes/stats/widgets/FrequencyStats.Widget.Row.vue";
import WidgetNbEventsParamButton from "@/components/common/widgets/parameter/WidgetNbEventsParamButton.vue";
import YoutubeButton from "@/components/common/YoutubeButton.vue";

export default {
  name: "FrequencyStatsWidget",
  components: {
    YoutubeButton,
    WidgetNbEventsParamButton,
    FrequencyStatsWidgetRow,
    AreaFlag,
    ArjelLogo,
    WidgetLoadingContent,
    LeagueLogo,
    FontAwesomeIcon,
    LeagueName,
    WidgetTeamParamButton,
    WidgetLocationParamButton,
    WidgetLeagueParamButton,
    WidgetPeriodParamButton,
    TeamName, TeamLogo
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: () => {
    return {
      refreshTimeout: null,
      data: null,
      hasMoreResult: false,

      forceShowMyStat: false,

      // Gestion du click long sur une ligne
      rowClickTimer: null,
    }
  },

  mounted() {
    window.emitter.on('stats-route:widgets-need-to-refresh', () => {
      this._refresh()
    })
    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
    this._refresh()
  },

  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _widget() {
      if (!this._itemUID) return null
      return perspectiveStore().getWidgetByUID(this._itemUID)
    },
    _currentPerspectiveUID() {
      return statsRouteStore().getSelectedPerspectiveUID()
    },
    _statFixedUID() {
      if(!this.item) return null
      return perspectiveStore().getParameterValue(this.item.uid, 'stat_fixed_uid')
    },
    _statFixedUIDCombined() {
      if(!this.item) return null
      return perspectiveStore().getParameterValue(this.item.uid, 'stat_fixed_uid_combined')
    },
    _statFixedUIDCombinedOperator() {
      if(!this.item) return null
      let parameterValue = perspectiveStore().getParameterValue(this.item.uid, 'stat_fixed_uid_combined_operator');
      if(!parameterValue) return 'AND'
      return parameterValue
    },
    _sdef(){
      if(!this._statFixedUID) return null
      return statStore().getDefinitionByFixedUID(this._statFixedUID)
    },
    _sdefCombined(){
      if(!this._statFixedUIDCombined) return null
      return statStore().getDefinitionByFixedUID(this._statFixedUIDCombined)
    },
    _statLabel() {
      if (!this._sdef) return '...'
      return this._sdef.name
    },
    _statCombinedLabel() {
      if (!this._sdefCombined) return 'Choisis une stat à combiner'
      return this._sdefCombined.name
    },
    _parameters() {
      return perspectiveStore().getParametersByWidgetUID(this._itemUID)
    },

    _statType() {
      if (!this._sdef) return null
      return this._sdef.type
    },

    _sortedData() {
      if (!this.data) return []

      // Create a copy of the data to avoid sorting the original data
      const sortedData = [...this.data]
      sortedData.sort((a, b) => {
        if (b.absolute - a.absolute) return 1
        if (a.absolute - b.absolute) return -1

        return a.gameDate - b.gameDate
      })
      return sortedData
    },

    _offerChoiceFixedUID() {
      if (!this._statFixedUID) return null
      return statStore().getOfferChoiceFixedUIDBySdefFixedUID(this._statFixedUID);
    },

    _offerArgument() {
      if(!this._sdef) return null
      return this._sdef.whatArgument
    },

    _period() {
      return perspectiveStore().getParameterValue(this.item.uid, 'period')
    },

  },
  watch: {
    _statFixedUID() {
      this._refresh()
    },
    _widget() {
      this._refresh()
    }
  },

  methods: {

    _refresh() {

      if(!this._widget) {
        return
      }

      const isInCurrentPerspective = this._widget.perspectiveUID === this._currentPerspectiveUID
      if(!isInCurrentPerspective) {
        return
      }

      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {
      this.data = null // => loading
      let offerChoiceFixedUIDs = null
      if (this._offerChoiceFixedUID) {
        offerChoiceFixedUIDs = []
        offerChoiceFixedUIDs.push(this._offerChoiceFixedUID['HOME'])
        offerChoiceFixedUIDs.push(this._offerChoiceFixedUID['AWAY'])
      }

      const payload = statsRouteStore().buildBaseWidgetPayload()
      payload.statFixedUID = this._statFixedUID
      payload.statFixedUIDCombined = this._statFixedUIDCombined
      payload.statFixedUIDCombinedOperator = this._statFixedUIDCombinedOperator
      payload.arjelOnly = selectionPaneStore().arjelOnly
      payload.location = perspectiveStore().getParameterValue(this.item.uid, 'location')
      payload.period = this._period,
      payload.league = perspectiveStore().getParameterValue(this.item.uid, 'league')
      payload.minEvent = perspectiveStore().getParameterValue(this.item.uid, 'min_event')
      payload.orderBy = perspectiveStore().getParameterValue(this.item.uid, 'order_by')
      payload.orderByDirection = perspectiveStore().getParameterValue(this.item.uid, 'order_by_direction')
      payload.offerChoiceFixedUIDs = offerChoiceFixedUIDs
      payload.offerArgument = this._offerArgument
      payload.sport = contextStore().getSport


      axiosService.put('/wid/stats/frequency', payload).then(response => {
        this.data = response.data.results
        this.hasMoreResult = response.data.hasMoreResult
      }).catch(error => {
        console.error(error)
      })
    },

    _updateOverContext(row, pin = false) {
      statsRouteStore().setOverContext({
        statUID: row.statUID,
        teamUID: row.teamUID,
        gameUID: row.gameUID,
        leagueUID: row.leagueUID,
        period: this._period
      }, pin)
    },

    _clearCombined(){
      // Si non authentifié, message d'erreur
      if (!contextStore().isAuthenticated()) {
        message.error('Tu dois être connecté pour personnalisé les statistiques');
        return;
      }
      let parameterDefinition = this._parameters.find(p => p.key === 'stat_fixed_uid_combined')
      perspectiveStore().setWidgetParameterValue(this.item.uid, parameterDefinition, {'key': null})
      let parameterDefinitionOperator = this._parameters.find(p => p.key === 'stat_fixed_uid_combined_operator')
      perspectiveStore().setWidgetParameterValue(this.item.uid, parameterDefinitionOperator, {'key': null})

      // On sauvegarde les paramètres
      perspectiveStore().saveWidgetParameters(this.item.uid)
      this.forceShowMyStat = false
    },

    _switchCombinedOperator(){
      if(!this._statFixedUIDCombinedOperator) {
        perspectiveStore().setWidgetParameterValue(this.item.uid, {'key': 'stat_fixed_uid_combined_operator'}, {'key': 'OR'})
      }else{
        let newOperator = this._statFixedUIDCombinedOperator === 'AND' ? 'OR' : 'AND';
        perspectiveStore().setWidgetParameterValue(this.item.uid, {'key': 'stat_fixed_uid_combined_operator'}, {'key': newOperator})
      }
      // On sauvegarde les paramètres
      perspectiveStore().saveWidgetParameters(this.item.uid)
    },

    _openStatSelectorModal(stat) {

      modalStore().openModal({
        uid: "statSelectorModal",
        payload: {
          statFixedUID: this._statFixedUID,
          category:'stat',
          type: this._statType,
          sport: contextStore().getSport,
          callback: (categorie, sdef) => {
            console.log('callback', categorie, sdef, stat)
            // Si non authentifié, message d'erreur
            if (!contextStore().isAuthenticated()) {
              message.error('Tu dois être connecté pour personnalisé les statistiques');
              return;
            }

            const paramKey = stat === 'base' ? 'stat_fixed_uid' : 'stat_fixed_uid_combined'

            let parameterDefinition = this._parameters.find(p => p.key === paramKey)
            if(!parameterDefinition) {
              // On modifie la configuration du widget pour spécifié la statistique utilisée.
              parameterDefinition = {
                'key': paramKey,
                'multiple': false,
              }
            }

            perspectiveStore().setWidgetParameterValue(this.item.uid, parameterDefinition, {'key': sdef.fixedUID})
            perspectiveStore().saveWidgetParameters(this.item.uid)
          }
        }

      });
    },

    _expend(){
      console.log('expend', this._statFixedUID)
      modalStore().openModal({
        uid: "frequencyStatsModal",
        payload: {
          widgetUID: this.item.uid,
          statFixedUID: this._statFixedUID,
          statFixedUIDCombined: this._statFixedUIDCombined
        }
      });
    },

    _showMyStat(){
      if(this._period === 'ALL_TIME'){
        message.info('MyStat ne fonctionne pas avec la période "Depuis toujours"');
        return;
      }
      this.forceShowMyStat = true
    },

    _startClickTimer(row) {
      if (!row.gameUID) {
        return
      }
      clearTimeout(this.rowClickTimer)
      this.rowClickTimer = null
      const self = this
      this.rowClickTimer = setTimeout(() => {
        self._updateOverContext(row, true)
        this.rowClickTimer = null
      }, 400)
    },

    _endClick(row) {
      if(this.rowClickTimer) {
        clearTimeout(this.rowClickTimer)
        this.rowClickTimer = null
      }
    }
  }
}
</script>

<template>
  <div class="widget team-event-stats">
    <div class="widget-header flex">
      Fréq
      <button class="small no-radius flex-grow text-left" @click="_openStatSelectorModal('base')" style="height: 28px">
        {{ _statLabel }}
      </button>
      <div class="widget-header-params">
        <widget-nb-events-param-button :widget="item"/>
        <widget-league-param-button :widget="item"/>
        <widget-period-param-button :widget="item"/>
        <widget-location-param-button :widget="item"/>
        <button class="mx-1 accent" @click="_showMyStat" style="height: 21px; line-height: 22px !important;">
          <font-awesome-icon icon="fa-solid fa-code-merge" style=""/>
        </button>
      </div>
    </div>
    <div class="widget-header my-stat m-1 flex" v-if="_statFixedUIDCombined || forceShowMyStat">
      <!---->
      <button class="small no-radius" @click="_switchCombinedOperator" style="height: 28px; padding-right: 8px !important; color: #ffa528 !important;">
        {{ _statFixedUIDCombinedOperator === 'OR' ? 'OU' : 'ET' }}
      </button>
      <button class="small no-radius flex-grow text-left" @click="_openStatSelectorModal('combined')" style="height: 28px; padding-left: 3px !important;">
        {{ _statCombinedLabel }}
      </button>
      <button class="small no-radius" style="line-height: 29px !important; height: 27px !important;" v-if="_statFixedUIDCombined" @click="_clearCombined">
        <font-awesome-icon icon="fa-solid fa-trash-can"/>
      </button>
      <youtube-button video-id="IlOsMl15Hho" size="tiny" style="margin-top: 4px; margin-right: 3px; margin-left: 3px"/>
    </div>
    <div class="widget-content">
      <div v-if="data">

        <frequency-stats-widget-row v-for="(r, index) in _sortedData"
                                    :row="r"
                                    :even="index % 2 === 0"
                                    @mouseenter="_updateOverContext(r)"
                                    @mousedown="_startClickTimer(r)" @mouseup="_endClick(r)"/>
        <a-alert v-if="_sortedData.length === 0" message="Aucune donnée disponible" type="warning" show-icon class="m-1"/>
        <button v-if="hasMoreResult" class="w-full small" @click="_expend">+ de résultats</button>
      </div>
      <widget-loading-content v-else/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.my-stat{
  background-color: @background-color-light;
  border: 1px solid @stroke-color;
  border-radius: @radius-regular;
  text-align: left;
  color: @text-color
}

table {
  .logo {
    width: 24px;
    text-align: center;
  }

  .name {
    text-align: left;
    max-width: 150px;
    padding: 0 !important;
  }

  .relative-value {
    width: 30px;
    text-align: right;
  }

  .absolute-value {
    width: 29px;
    text-align: right;
    color: @text-color-dark;
  }

  tr {
    cursor: pointer;

    &.over-context-league {
      background: fade(@hover-color, 50%);
    }

    &.over-context-game {
      background: fade(@selected-color, 75%);
    }

    &.over-context-team {
      background: fade(@primary-color, 40%);
    }

    &:hover {
      background: fade(@primary-color, 40%);
    }

    &.anonymous{
      opacity: 0.25;
    }

    .against-data {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 7pt;
      color: @text-color-dark;
    }
  }
}

</style>