<script>
import {modalStore} from "@/stores/Modal.store";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import axiosService from "@/services/axios.service";
import {statStore} from "@/stores/Statistic.store";
import TeamName from "@/components/common/TeamName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import {contextStore} from "@/stores/Context.store";
import SerieStatsWidgetRow from "@/components/routes/stats/widgets/SerieStats.Widget.Row.vue";


export default {
  name: "SerieStatsModal",
  components: {SerieStatsWidgetRow, LeagueLogo, TeamLogo, TeamName},
  data() {
    return {
      data: null
    }
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'serieStatsModal';
    },

    _payload() {
      if (!this._isVisible) return null;
      const payload = modalStore().getVisibleModal.payload
      if (!payload) return null;
      return payload;
    },

    _widgetUID() {
      if (!this._payload) return null;
      return this._payload.widgetUID;
    },

    _statFixedUID() {
      if (!this._payload) return null;
      return this._payload.statFixedUID;
    },

    _statLabel() {
      let definitionByFixedUID = statStore().getDefinitionByFixedUID(this._statFixedUID);
      if (!definitionByFixedUID) return '...'
      return definitionByFixedUID.name
    },

    _sortedData() {
      if (!this.data) return []

      // on crée une copie du tableau pour ne pas modifier l'original
      const sortedData = [...this.data]
      return sortedData.sort((a, b) => {
        if (b.absolute - a.absolute) return 1
        if (a.absolute - b.absolute) return -1

        return a.gameDate - b.gameDate
      })
    },
    _offerChoiceFixedUID() {
      if (!this._statFixedUID) return null
      return statStore().getOfferChoiceFixedUIDBySdefFixedUID(this._statFixedUID);
    },
  },
  methods: {
    _onCancel() {
      modalStore().closeModal()
    },
    _refresh() {

      let offerChoiceFixedUIDs = null
      if (this._offerChoiceFixedUID) {
        offerChoiceFixedUIDs = []
        offerChoiceFixedUIDs.push(this._offerChoiceFixedUID['HOME'])
        offerChoiceFixedUIDs.push(this._offerChoiceFixedUID['AWAY'])
      }

      const payload = statsRouteStore().buildBaseWidgetPayload()
      payload.statFixedUID = this._statFixedUID
      payload.location = perspectiveStore().getParameterValue(this._widgetUID, 'location')
      payload.period = perspectiveStore().getParameterValue(this._widgetUID, 'period')
      payload.league = perspectiveStore().getParameterValue(this._widgetUID, 'league')
      payload.limit = 100 // On demande 100 lignes
      payload.orderBy = perspectiveStore().getParameterValue(this._widgetUID, 'order_by')
      payload.orderByDirection = perspectiveStore().getParameterValue(this._widgetUID, 'order_by_direction')
      payload.offerChoiceFixedUIDs = offerChoiceFixedUIDs
      payload.offerArgument = this._offerArgument
      payload.sport = contextStore().getSport

      axiosService.put('/wid/stats/serie', payload).then(response => {
        this.data = response.data.results
      }).catch(error => {
        console.error(error)
      })
    },

    _openGameDetailsModal(gameUID) {
      modalStore().openModal({
        uid: "gameDetailsModal",
        payload: {
          gameUID: gameUID
        }
      });
    }
  },
  watch: {
    _isVisible(val) {
      if (val) {
        this._refresh()
      }
    }
  },
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      {{ _statLabel }}
    </header>

    <div class="content">
      <div v-if="data">
        <serie-stats-widget-row v-for="(r, index) in _sortedData"
                                :row="r"
                                :even="index % 2 === 0"
                                @click="_openGameDetailsModal(r.gameUID)"/>
      </div>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="" @click="_onCancel">Fermer</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table {
  .logo {
    width: 24px;
    text-align: center;
  }

  .name {
    text-align: left;
    max-width: 150px;
    padding: 0 !important;
  }


  .relative-value {
    width: 52px;
    text-align: right;
    color: @text-color-dark;
  }

  .absolute-value {
    width: 24px;
    text-align: right;

  }

  tr {
    cursor: pointer;

    &:hover {
      background: fade(@primary-color, 40%);
    }

    &.anonymous {
      opacity: 0.25;
    }

    .against-data {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 7pt;
      color: @text-color-dark;
    }
  }
}
</style>