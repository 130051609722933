<script>
import axiosService from "@/services/axios.service";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import {teamStore} from "@/stores/Team.store";
import {preferencesStore} from "@/stores/Preferences.store";
import {leagueStore} from "@/stores/League.store";
import TeamName from "@/components/common/TeamName.vue";
import GameDate from "@/components/common/GameDate.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import YoutubeButton from "@/components/common/YoutubeButton.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";

export default {
  name: "SelectionPaneItem.Forme.Tab",
  components: {OfferChoiceButton, YoutubeButton, LeagueLogo, GameDate, TeamName, TeamLogo, GameResultCircle},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      data: null,

      filters: {
        type: 'ALL',
        moment: 'FT',
        league: 'ANY'
      },

      overGameUID: null
    }
  },
  beforeMount() {

    // On va chercher la préférence du type
    let type = preferencesStore().getFirstValue('SELECTION_FORME_TAB', 'TYPE');
    if (type) {
      this.filters.type = type
    } else {
      preferencesStore().save('SELECTION_FORME_TAB', 'TYPE', [this.filters.type])
    }

    // On va chercher la préférence du moment
    let moment = preferencesStore().getFirstValue('SELECTION_FORM_TAB', 'MOMENT_' + this._sport);
    if (moment) {
      this.filters.moment = moment
    } else {
      preferencesStore().save('SELECTION_FORM_TAB', 'MOMENT_' + this._sport, [this.filters.moment])
    }

    // On va chercher la préférence de la league
    let league = preferencesStore().getFirstValue('SELECTION_FORME_TAB', 'LEAGUE');
    if (league) {
      this.filters.league = league
    } else {
      preferencesStore().save('SELECTION_FORME_TAB', 'LEAGUE', [this.filters.league])
    }

    this._refreshData()
  },

  computed: {
    _sport() {
      if (!this._game) return null
      return this._game.sport
    },
    _moment() {
      switch (this._sport) {
        case 'FOOTBALL':
          return ['FT', 'P1', 'P2']
        case 'BASKETBALL':
          return ['FT', 'P1', 'P2', 'P3', 'P4']
        case 'HANDBALL':
          return ['FT', 'P1', 'P2']
        case 'RUGBY':
          return ['FT', 'P1', 'P2']
        case 'HOCKEY':
          return ['FT', 'P1', 'P2', 'P3']
        case 'TENNIS':
          return ['FT', 'P1', 'P2', 'P3', 'P4', 'P5']
      }
    },
    _momentLabel() {
      return this.filters.moment
    },
    _game() {
      return gameStore().get(this.gameUid)
    },
    _homeTeamUID() {
      if (!this._game) return null
      return this._game.homeTeamUID
    },
    _homeTeam() {
      if (!this._homeTeamUID) return null
      return teamStore().get(this._homeTeamUID)
    },
    _homeTeamName() {
      if (!this._homeTeam) return '-'
      return this._homeTeam.name
    },
    _awayTeamUID() {
      if (!this._game) return null
      return this._game.awayTeamUID
    },
    _awayTeam() {
      if (!this._awayTeamUID) return null
      return teamStore().get(this._awayTeamUID)
    },
    _awayTeamName() {
      if (!this._awayTeam) return '-'
      return this._awayTeam.name
    },
    _homeGames() {
      if (!this.data) return []
      if (!this.data.home) return []
      if (!this.data.home.gameUIDs) return []

      const games = []
      this.data.home.gameUIDs.forEach(uid => {
        let game = gameStore().get(uid);
        if (!game) return
        games.push(game)
      })

      games.sort((b, a) => {
        if (a.date === b.date) return 0
        if (a.date < b.date) return 1
        if (a.date > b.date) return -1
      })

      if(games.length < 10) {
        let number = 10 - games.length;
        for(let i = 0; i < number; i++) {
          games.push(null)
        }
      }

      return games
    },
    _homeGames5Last() {
      const notNullGames = this._homeGames.filter(g => g != null)
      if(notNullGames.length < 5) {
        return notNullGames.reverse()
      }
      return notNullGames.reverse().slice(0, 5)
    },
    _awayGames() {
      if (!this.data) return []
      if (!this.data.away) return []
      if (!this.data.away.gameUIDs) return []

      const games = []
      this.data.away.gameUIDs.forEach(uid => {
        let game = gameStore().get(uid);
        if (!game) return
        games.push(game)
      })
      games.sort((b, a) => {
        if (a.date === b.date) return 0
        if (a.date < b.date) return 1
        if (a.date > b.date) return -1
      })

      if(games.length < 10) {
        let number = 10 - games.length;
        for(let i = 0; i < number; i++) {
          games.push(null)
        }
      }

      return games
    },
    _awayGames5Last() {
      const notNullGames = this._awayGames.filter(g => g != null)
      if(notNullGames.length < 5) {
        return notNullGames.reverse()
      }
      return notNullGames.reverse().slice(0, 5)
    },
    _shouldShowTypeFilter() {
      if(!this._sport) {
        return false
      }
      return this._sport !== 'TENNIS'
    }
  },

  methods: {
    _diff(gameUID, teamUID) {
      const game = gameStore().get(gameUID)
      if (!game) return null

      let homeScore = null
      let awayScore = null
      switch(this.filters.moment){
        case 'FT':
          homeScore = game.scoreFtHome
          awayScore = game.scoreFtAway
          break
        case 'P1':
          homeScore = game.scoreP1Home
          awayScore = game.scoreP1Away
          break
        case 'P2':
          homeScore = game.scoreP2Home
          awayScore = game.scoreP2Away
          break
        case 'P3':
          homeScore = game.scoreP3Home
          awayScore = game.scoreP3Away
          break
        case 'P4':
          homeScore = game.scoreP4Home
          awayScore = game.scoreP4Away
          break
        case 'P5':
          homeScore = game.scoreP5Home
          awayScore = game.scoreP5Away
          break
      }

      if (homeScore === null || awayScore === null) return null
      const diff = game.homeTeamUID === teamUID ? homeScore - awayScore : awayScore - homeScore

      if (diff === 0) {
        return ""
      }
      if (diff > 0) {
        return "+" + diff
      } else {
        return diff
      }
    },

    _offers(game) {
      if(!game) {
        return []
      }

      const offers = []
      if(game.odd1X2HomeValue) {
        offers.push(game.odd1X2HomeValue.toFixed(2))
      }
      if(game.odd1X2DrawValue) {
        offers.push(game.odd1X2DrawValue.toFixed(2))
      }
      if(game.odd1X2AwayValue) {
        offers.push(game.odd1X2AwayValue.toFixed(2))
      }
      return offers
    },

    setOverGameUID(game) {
      if(!game) {
        return
      }
      if(this.overGameUID === game.uid) {
        return
      }
      this.overGameUID = game.uid
    },

    _setType(type) {
      this.filters.type = type
      preferencesStore().save('SELECTION_FORME_TAB', 'TYPE', [this.filters.type])

      this._refreshData()
    },

    _setLeague(league) {
      this.filters.league = league
      preferencesStore().save('SELECTION_FORME_TAB', 'LEAGUE', [this.filters.league])

      this._refreshData()
    },

    _switchMoment() {
      const index = this._moment.indexOf(this.filters.moment)
      this.filters.moment = this._moment[(index + 1) % this._moment.length]

      preferencesStore().save('SELECTION_FORM_TAB', 'MOMENT_' + this._sport, [this.filters.moment])
    },

    _refreshData() {
      const payload = {
        uid: this.gameUid,
        locationStrict: this.filters.type === 'DOM_EXT',
        league: this.filters.league
      }
      axiosService.put('/dta/game/basic-data/forme', payload).then(response => {
        this.data = response.data
      })
    },
    _isCurrentSeason(game) {
      if (!game) return false
      const league = leagueStore().get(game.leagueUID)
      if (!league) return false
      return game.seasonName === league.currentSeason
    },

    _homeScore(game) {
      if (!game) return '?'
      switch (this.filters.moment){
        case 'FT':
          return game.scoreFtHome
        case 'P1':
          return game.scoreP1Home
        case 'P2':
          return game.scoreP2Home
        case 'P3':
          return game.scoreP3Home
        case 'P4':
          return game.scoreP4Home
        default:
          return '?'
      }
    },
    _awayScore(game) {
      if (!game) return '?'
      switch (this.filters.moment){
        case 'FT':
          return game.scoreFtAway
        case 'P1':
          return game.scoreP1Away
        case 'P2':
          return game.scoreP2Away
        case 'P3':
          return game.scoreP3Away
        case 'P4':
          return game.scoreP4Away
        default:
          return '?'
      }
    }
  },
}
</script>

<template>
  <div>
    <div class="flex">
      <fieldset class="m-1 p-0" v-if="_shouldShowTypeFilter">
        <legend class="m-0">Type</legend>
        <button :class="{'small no-radius' : true, 'primary selected' : filters.type === 'ALL'}"
                style="height: 22.3px"
                @click="_setType('ALL')">Tous
        </button>

        <button :class="{'small no-radius' : true, 'primary selected' : filters.type === 'DOM_EXT'}"
                style="height: 22.3px"
                @click="_setType('DOM_EXT')">D/E
        </button>
      </fieldset>

      <fieldset class="m-1 p-0">
        <legend class="m-0">Moment</legend>
        <button :class="{'small no-radius' : true}" style="width:46px"
                @click="_switchMoment">{{ _momentLabel }}
        </button>
      </fieldset>

      <fieldset class="m-1 p-0">
        <legend class="m-0">Compétition</legend>
        <button :class="{'small no-radius' : true, 'primary selected' : filters.league === 'ANY'}"
                style="height: 22.3px"
                @click="_setLeague('ANY')">Toutes
        </button>

        <button :class="{'small no-radius' : true, 'primary selected' : filters.league === 'CURRENT'}"
                style="height: 22.3px"
                @click="_setLeague('CURRENT')">Courante
        </button>
      </fieldset>
    </div>
    <table v-if="data">
      <tr>
        <th>

        </th>
        <th colspan="5" class="sr">
          L10
        </th>
        <th colspan="5">
          L5
        </th>
      </tr>
      <tr style="height: 50px">
        <td>
          <team-logo :uid="_game.homeTeamUID"/>
        </td>
        <td v-for="game in _homeGames" :class="{'previous-season' : !_isCurrentSeason(game), 'over': game && game.uid === overGameUID}" @mouseenter="setOverGameUID(game)">
          <div style="position: absolute; top: 4px; width: 80%; " v-if="game != null">
            {{ _diff(game.uid, _game.homeTeamUID) }}
          </div>
          <game-result-circle :game-uid="game.uid" :team-uid="_game.homeTeamUID" :moment="filters.moment" class="mt-2 ml-1" v-if="game != null"/>
          <game-result-circle :game-uid="null" :team-uid="null" :moment="filters.moment" class="mt-2 ml-1" v-else/>
        </td>
      </tr>
      <tr style="height: 50px">
        <td>
          <team-logo :uid="_game.awayTeamUID"/>
        </td>
        <td v-for="game in _awayGames" :class="{'previous-season' : !_isCurrentSeason(game), 'over': game && game.uid === overGameUID}" @mouseenter="setOverGameUID(game)">
          <div style="position: absolute; top: 4px; width: 80%; " v-if="game != null">
            {{ _diff(game.uid, _game.awayTeamUID) }}
          </div>
          <game-result-circle :game-uid="game.uid" :team-uid="_game.awayTeamUID" :moment="filters.moment" class="mt-2 ml-1" v-if="game != null"/>
          <game-result-circle :game-uid="null" :team-uid="null" :moment="filters.moment" class="mt-2 ml-1" v-else/>
        </td>
      </tr>
    </table>

    <fieldset class="no-border text-left">
      <legend>Matchs de {{ _homeTeamName }} <span v-if="filters.type==='DOM_EXT'">à domicile</span></legend>
      <div v-for="g in _homeGames5Last">
        <table :class="{'last-team-game-row': true, 'over': g.uid === overGameUID}" @mouseenter="setOverGameUID(g)" v-if="g != null">
          <tr>
            <td class="date" rowspan="2">
              <game-date :uid="g.uid"/>
            </td>
            <td class="league" rowspan="2">
              <league-logo :uid="g.leagueUID"/>
            </td>
            <td class="team-logo">
              <team-logo :uid="g.homeTeamUID" size="small"/>
            </td>
            <td class="team-name">
              <team-name :uid="g.homeTeamUID"/>
            </td>
            <td class="offers" rowspan="2">
              <button class="small" v-for="offer in _offers(g)" style="height: 16px; line-height: 16px !important;">
                  {{offer}}
              </button>
            </td>
            <td class="score">{{ _homeScore(g) }}</td>
            <td class="result" rowspan="2">
              <game-result-circle :team-uid="_homeTeamUID" :game-uid="g.uid" :moment="filters.moment"/>
            </td>
          </tr>
          <tr>
            <td class="team-logo">
              <team-logo :uid="g.awayTeamUID" size="small"/>
            </td>
            <td class="team-name">
              <team-name :uid="g.awayTeamUID"/>
            </td>
            <td class="score">{{ _awayScore(g) }}</td>
          </tr>
        </table>
      </div>
    </fieldset>

    <fieldset class="no-border text-left mt-2">
      <legend>Matchs de {{ _awayTeamName }} <span v-if="filters.type==='DOM_EXT'">à l'extérieur</span></legend>
      <div v-for="g in _awayGames5Last">
        <table :class="{'last-team-game-row': true, 'over': g & g.uid === overGameUID}" @mouseenter="setOverGameUID(g)" v-if="g != null">
          <tr>
            <td class="date" rowspan="2">
              <game-date :uid="g.uid"/>
            </td>
            <td class="league" rowspan="2">
              <league-logo :uid="g.leagueUID"/>
            </td>
            <td class="team-logo">
              <team-logo :uid="g.homeTeamUID" size="small"/>
            </td>
            <td class="team-name">
              <team-name :uid="g.homeTeamUID"/>
            </td>

            <td class="offers" rowspan="2">
              <button class="small" v-for="offer in _offers(g)" style="height: 16px; line-height: 16px !important;">
                {{offer}}
              </button>
            </td>
            <td class="score">{{ _homeScore(g) }}</td>
            <td class="result" rowspan="2">
              <game-result-circle :team-uid="_awayTeamUID" :game-uid="g.uid" :moment="filters.moment"/>
            </td>
          </tr>
          <tr>
            <td class="team-logo">
              <team-logo :uid="g.awayTeamUID" size="small"/>
            </td>
            <td class="team-name">
              <team-name :uid="g.awayTeamUID"/>
            </td>
            <td class="score">{{ _awayScore(g) }}</td>
          </tr>
        </table>
      </div>
    </fieldset>
    <hr/>
    <div class="flex p-2">
      <p class="pr-2 m-0 flex-grow">🔍 Analyse la forme des deux équipes sous l'angle qui t'intéresse le plus ! 📊 Tout est là pour affiner tes paris ! 🎯</p>
      <youtube-button video-id="N3RDKy7dh_E"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table.last-team-game-row {
  border-bottom: 1px solid @stroke-color;

  tr, td{
    border: none;
    padding: 0;
    background: transparent;
  }

  &.over{
    background-color: @hover-color;
  }

  .date {
    width: 64px;
  }

  .league {
    width: 32px;
  }

  .team-logo {
    width: 32px;
  }

  .team-name {
    text-align: left;
  }

  .offers{
    width: 32px;
    text-align: right;
  }

  .score {
    width: 32px;
  }

  .result {
    width: 36px;
    padding: @padding-regular;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: @text-color;
  font-size: @font-size-small;

  tr {
    border-bottom: 1px solid @stroke-color;

    &:last-child {
      border-bottom: none;
    }

    th, td {
      padding: @padding-small;
      text-align: center;
      font-family: 'SourceCodePro', serif;
      font-weight: 400;

      &.over {
        background-color: @hover-color;
      }
    }

    th {
      background-color: @background-color-light;
    }

    td {
      &.label {
        text-orientation: mixed;
        writing-mode: vertical-rl;

        &.ll {
          border-left: 1px solid @stroke-color;
        }
      }
    }

    .previous-season {
      opacity: 0.33;
    }
  }
}
</style>