<script>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {colorStore} from "@/stores/Color.store";
import {contextStore} from "@/stores/Context.store";
import {leagueStore} from "@/stores/League.store";
import {imageStore} from "@/stores/Image.store";

export default {
  name: "LeagueLogo",
  components: {FontAwesomeIcon},
  props: {
    uid: {
      required: true
    },
    size: {
      type: String,
      default: "medium"
    },
    forceShow:{
      type: Boolean,
      default: false
    }
  },

  beforeMount() {
    if(!this._league) return null
    if(!this._league.imageUID) return null
    imageStore().get(this._league.imageUID)
  },

  computed: {
    _contextStore() {
      return contextStore();
    },
    _leagueStore() {
      return leagueStore();
    },
    _league() {
      return this._leagueStore.get(this.uid);
    },
    _leagueName() {
      return this._league?.name ?? "_";
    },
    _leagueSport() {
      return this._league?.sport ?? null;
    },
    _leagueExternalUID() {
      if(!this._league) return null
      return this._league?.externalUID ?? null;
    },
    _buildCssClasses() {
      return {
        'league-logo': true,
        'small': this.size === 'small',
        'medium': this.size === 'medium',
        'large': this.size === 'large'
      }
    },
    _canDisplayLogo() {
      if(!this._league) return false
      if(this._leagueSport === 'TENNIS') return false
      if(this.forceShow) return true
      return this._leagueExternalUID !== null;
    },
    _altColor() {

      if (!this.uid) {
        return 'grey';
      }

      const cs = colorStore();
      return cs.get(this._leagueName);
    },
    _buildCssStyle() {
      return {
        color: this._altColor,
        height: '22px'
      }
    },
    _buildIcons() {
      const icons = ['fa-solid'];
      if (!this.uid) {
        icons.push('fa-globe');
      } else {
        icons.push('fa-trophy');
      }
      return icons;
    },
    _base64() {
      if(!this._league) return null
      if(!this._league.imageUID) return null
      const image = imageStore().get(this._league.imageUID)
      if(!image) return null
      return 'data:image/png;base64,' + image.data
    }

  },
}
</script>

<template>
  <span>
    <font-awesome-icon :icon="_buildIcons" :class="_buildCssClasses" v-if="!_canDisplayLogo || !_base64" :style="_buildCssStyle"/>
    <img :class="_buildCssClasses" :src="_base64" v-else-if="_base64" alt=""/>
  </span>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.league-logo {

  display: inline-block;
  max-height: 24px;
  max-width: 24px;

  &.small {
    max-height: 12px;
    max-width: 12px;
  }

  &.medium {
    max-height: 16px;
    max-width: 16px;
  }

  &.large {
    max-height: 64px;
    max-width: 64px;
  }
}
</style>