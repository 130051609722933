// stores/counter.js
import {defineStore} from 'pinia'
import {contextStore} from "@/stores/Context.store";
import {gameStore} from "@/stores/Game.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import {preferencesStore} from "@/stores/Preferences.store";

export const analysePaneStore = defineStore('analysePane', {
    state: () => {
        return {
            data: {
                'FOOTBALL': {
                    perspectiveUID: null,
                    gameUIDs: []
                },
                'BASKETBALL': {
                    perspectiveUID: null,
                    gameUIDs: []
                },
                'RUGBY': {
                    perspectiveUID: null,
                    gameUIDs: []
                },
                'HANDBALL': {
                    perspectiveUID: null,
                    gameUIDs: []
                },
                'HOCKEY': {
                    perspectiveUID: null,
                    gameUIDs: []
                },
                'AMERICAN_FOOTBALL': {
                    perspectiveUID: null,
                    gameUIDs: []
                },
                'TENNIS': {
                    perspectiveUID: null,
                    gameUIDs: []
                }
            }
        }
    },
    actions: {
        onSportChange() {
            const sport = contextStore().getSport
            let lastPerspectiveBySport = this.data[sport].perspective
            if(!lastPerspectiveBySport){
                const sportPerspectives = perspectiveStore().getBySportAndType(sport, 'GAME')
                if(sportPerspectives.length > 0){
                    lastPerspectiveBySport = sportPerspectives[0]
                }
            }

            if(!lastPerspectiveBySport){
                console.debug('No perspective found for sport', sport)
                return
            }

            // get first game of the sport
            if(this.data[sport].gameUIDs.length === 0){
                this.setPerspective(null, lastPerspectiveBySport.uid)
                return
            }

            const gameUID = this.data[sport].gameUIDs[0]
            this.setPerspective(gameUID, lastPerspectiveBySport.uid)
        },
        setPerspective(gameUID, perspectiveUID) {
            const perspective = perspectiveStore().get(perspectiveUID)
            if (!perspective) return

            perspectiveStore().resetAllEditMode()

            this.data[perspective.sport].perspective = perspective
        },
        addGame(gameUID, sport) {
            if (this.data[sport].gameUIDs.indexOf(gameUID) < 0) {
                this.data[sport].gameUIDs.push(gameUID)

                const gs = gameStore()
                const game = gs.get(gameUID)
                if (!game) {
                    return
                }

                let lastPerspectiveBySportElement = this.data[game.sport].perspective;
                if (!lastPerspectiveBySportElement) {
                    const ps = perspectiveStore()
                    // On prend celle défini dans les préférences utilisateur
                    const prefUID = preferencesStore().getFirstValue('DEFAULT_PERSPECTIVE', game.sport + '_PERSPECTIVE')
                    if (prefUID) {
                        lastPerspectiveBySportElement = ps.get(prefUID)
                    }

                    if(!lastPerspectiveBySportElement) {
                        // On prend la premiere perspective du sport
                        const allSportPerspectives = ps.getBySportAndType(game.sport, 'GAME')
                        if (allSportPerspectives.length > 0) {
                            lastPerspectiveBySportElement = allSportPerspectives[0]
                        }
                    }
                }

                if(lastPerspectiveBySportElement){
                    analysePaneStore().setPerspective(gameUID, lastPerspectiveBySportElement.uid)
                }else{
                    console.error('No perspective found for sport', game.sport)
                }

            }

            const cs = contextStore()
            cs.setGameUID(gameUID)
        },
        removeGame(gameUID) {

            for (const key of Object.keys(this.data)) {
                const gameUIDs = this.data[key].gameUIDs
                const index = gameUIDs.indexOf(gameUID)
                if (index >= 0) {
                    gameUIDs.splice(index, 1)

                    // On selectionne un autre game
                    if (gameUIDs.length > 0) {
                        if (index === 0) {
                            this.addGame(gameUIDs[0], key);
                        } else {
                            this.addGame(gameUIDs[index - 1], key)
                        }
                    }

                    if (gameUIDs.length === 0) {
                        contextStore().setGameUID(null)
                    }
                }

            }
        }
    },
    getters: {
        getGameUIDsBySport:(state) => (sport) => {
            return state.data[sport].gameUIDs
        },
        getPerspectiveBySport:(state) => (sport) => {
            return state.data[sport].perspective
        }
    }
})