<script>
import {contextStore} from "@/stores/Context.store";

import MainHeader from "@/components/common/MainHeader.vue";
import ProfilerRouteHeader from "@/components/routes/profiler/ProfilerRouteHeader.vue";
import ProfilerWelcomePane from "@/components/routes/profiler/panes/welcome/ProfilerWelcomePane.vue";
import ProfilerAnalysePane from "@/components/routes/profiler/panes/analyse/ProfilerAnalysePane.vue";
import ProfilerFilterPane from "@/components/routes/profiler/panes/filter/ProfilerFilterPane.vue";

export default {
  name: "ProfilerRoute",
  components: {
    MainHeader, ProfilerAnalysePane, ProfilerFilterPane,
    ProfilerRouteHeader,
    ProfilerWelcomePane
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
  },
  methods: {

  },
  beforeMount() {

    if (contextStore().shouldReload()) {
      window.location.href = window.location.origin
    }

    if (!contextStore().isInitialized()) {
      this.$router.push({name: 'init', query: {redirect: 'profiler', query: this._urlUID}})
      return
    }
  }
}
</script>

<template>
  <div>
    <main-header/>
    <div class="flex">
      <profiler-filter-pane/>
      <profiler-analyse-pane/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#team-content-wrapper {
  height: calc(100vh - @toolbar-regular * 3);
  gap: @padding-regular; /* Adjust the gap as needed */
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;

  margin: @padding-small;
  border-radius: @radius-regular;

  .widget {
    flex: 1 1 350px; /* permet aux div de croître et se contracter, avec une base de 350px */
    box-sizing: border-box;
    width: 350px;
    max-width: calc(100%);
  }
}

#stat-pane {
  margin: @padding-small;
  border-radius: @radius-regular;
  background: rgb(94,53,43);
  background: linear-gradient(122deg, rgba(94,53,43,1) 0%, rgba(94,53,43,0.95) 3%, rgba(37,37,42,0.85) 21%, rgba(37,37,42,0.75) 100%);
  height: calc(100dvh - @toolbar-regular);
}

#empty-perspective {
  margin-left: auto;
  margin-right: auto;
  p {
    margin: 200px auto @padding-large auto;
    max-width: 400px;
    text-align: center;
    font-size: 2em !important;
    color: @text-color;
    opacity: 0.1;
  }
}

@media (min-width: 599px) {F
  #empty-perspective {
    width: calc(100vw - 400px);
  }
}

#just-sign-in{
  position: fixed;
  bottom: 12px;
  right: 12px;
  width: 300px;
  border: 1px solid @stroke-color;
  border-radius: @radius-regular;
  background: #494081;

  p{
    text-align: center;
  }
}
</style>