// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "@/services/axios.service";

export const profilerRouteStore = defineStore('profilerRouteStore', {
    state: () => {
        return {
            data: null,
            filters: null,
            refreshTimeout: null
        }
    },
    actions: {
        applyFilters(filters) {
            this.filters = filters
            if (this.refreshTimeout) {
                clearTimeout(this.refreshTimeout)
            }
            this.refreshTimeout = setTimeout(() => {
                this.refreshData()
            }, 500)
        },
        refreshData() {
            this.data = null
            axiosService.post('/prf/data', this.filters).then(response => {
                this.data = response.data
            }).catch(error => {
                console.error(error)
            })
        },
        getWidgetTitle(property) {
            switch (property) {
                case 'homeWinProbability':
                    return "Proba. Victoire Dom. (Bookmaker)"
                case 'drawProbability':
                    return "Proba. Nul (Bookmaker)"
                case 'awayWinProbability':
                    return "Proba. Victoire Ext. (Bookmaker)"
                case 'homeWinOdd':
                    return "Cote Domicile"
                case 'drawOdd':
                    return "Cote Nul"
                case 'awayWinOdd':
                    return "Cote Extérieur"
                case 'bttsYesOdd':
                    return "Cote BTTS Oui"
                case 'bttsNoOdd':
                    return "Cote BTTS Non"
                case 'bttsYesProbability':
                    return "Proba. BTTS Oui"
                case 'bttsNoProbability':
                    return "Proba. BTTS Non"
                case 'under25Odd':
                    return "Cote -2.5 buts"
                case 'over25Odd':
                    return "Cote +2.5 buts"
                case 'under25Probability':
                    return "Proba. -2.5 buts"
                case 'over25Probability':
                    return "Proba. +2.5 buts"
                case 'homeBttsRateSeason':
                    return "BTTS Domicile Saison"
                case 'awayBttsRateSeason':
                    return "BTTS Extérieur Saison"
                case 'homeBttsRateSeasonAtHome':
                    return "BTTS Domicile Saison Domicile"
                case 'awayBttsRateSeasonAtAway':
                    return "BTTS Extérieur Saison Extérieur"
                case 'homeWinRateSeason':
                    return "Victoire Domicile Saison"
                case 'awayWinRateSeason':
                    return "Victoire Extérieur Saison"
                case 'homeWinRateSeasonAtHome':
                    return "Victoire Domicile Saison Domicile"
                case 'awayWinRateSeasonAtAway':
                    return "Victoire Extérieur Saison Extérieur"
                case 'homeLoseRateSeason':
                    return "Défaite Domicile Saison"
                case 'awayLoseRateSeason':
                    return "Défaite Extérieur Saison"
                case 'homeLoseRateSeasonAtHome':
                    return "Défaite Domicile Saison Domicile"
                case 'awayLoseRateSeasonAtAway':
                    return "Défaite Extérieur Saison Extérieur"
                case 'homeOver05RateSeasonAtHome':
                    return "+0.5 buts Domicile Saison Domicile"
                case 'homeOver05RateSeason':
                    return "+0.5 buts Domicile Saison"
                case 'awayOver05RateSeasonAtAway':
                    return "+0.5 buts Extérieur Saison Extérieur"
                case 'awayOver05RateSeason':
                    return "+0.5 buts Extérieur Saison"
                case 'homeOver15RateSeasonAtHome':
                    return "+1.5 buts Domicile Saison Domicile"
                case 'homeOver15RateSeason':
                    return "+1.5 buts Domicile Saison"
                case 'awayOver15RateSeasonAtAway':
                    return "+1.5 buts Extérieur Saison Extérieur"
                case 'awayOver15RateSeason':
                    return "+1.5 buts Extérieur Saison"
                case 'homeOver25RateSeasonAtHome':
                    return "+2.5 buts Domicile Saison Domicile"
                case 'homeOver25RateSeason':
                    return "+2.5 buts Domicile Saison"
                case 'awayOver25RateSeasonAtAway':
                    return "+2.5 buts Extérieur Saison Extérieur"
                case 'awayOver25RateSeason':
                    return "+2.5 buts Extérieur Saison"

                default:
                    return "##" + property + "##"
            }
        }
    },
    getters: {}
})