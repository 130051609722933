<script>
import axiosService from "@/services/axios.service";
import {contextStore} from "@/stores/Context.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import StatisticValueButton from "@/components/common/StatisticValueButton.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import {perspectiveStore} from "@/stores/Perspective.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import {gameStore} from "@/stores/Game.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import {sportStore} from "@/stores/Sport.store";

export default {
  name: "GoalsByPeriodWidget",
  components: {
    WidgetLoadingContent,
    FontAwesomeIcon,
    WidgetPeriodParamButton,
    WidgetLeagueParamButton,
    WidgetLocationParamButton,
    StatisticValueButton,
    TeamLogo
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: null,
      chart: {
        key: 0,
        type: 'bar',
        width: 310,
        options: {
          chart: {
            stacked: true,
          },
          dataLabels: {
            formatter: (val) => {
              return val
            }
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          legend: {
            show: false,
            border: {
              show: false,
            },
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                colors: '#FFF' // Change this to your desired color
              },
              formatter: (val) => {
                return val.toFixed(0) + ' ' + this._pointName.toLowerCase()
              }
            }
          },
          yaxis: {
            labels: {
              style: {
                colors: '#FFF' // Change this to your desired color
              }
            }
          },
          fill: {
            opacity: 1,
          },
          colors: [
            '#516dd8',  // Home+
            '#169272',  // Home-

            '#FF846E',  // Away+
            '#865762'], // Away-
        },
        series: [
          {
            name: 'Home+',
            group: 'plus',
            data: []
          },
          {
            name: 'Away+',
            group: 'plus',
            data: []
          },
          {
            name: 'Home-',
            group: 'less',
            data: []
          },
          {
            name: 'Away-',
            group: 'less',
            data: []
          }
        ],
      }
    }
  },
  mounted() {

    this._onGameUIDChange()

    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })

    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })

    // On initialise les catégories en fonction du sport
    const categories = [
      'P1',
      'P2'
    ]
    switch (this._currentSport) {
      case 'FOOTBALL':
        break
      case 'HOCKEY':
        categories.push('P3')
        break
      case 'BASKETBALL':
        categories.push('P3')
        categories.push('P4')
        break
      case 'TENNIS':
        categories.push('P3')
        categories.push('P4')
        categories.push('P5')
        break
    }
    this.chart.options.xaxis.categories = categories
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _contextStore() {
      return contextStore();
    },
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _currentSport() {
      return contextStore().getSport
    },
    _pointName() {
      return sportStore().getPointName(this._currentSport)
    }
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },

    _refresh() {
      if (!contextStore().gameUID) return;

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) return;

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {

      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        this._updateChart()
        return;
      }

      const game = gameStore().get(contextStore().gameUID)
      if (!game) {
        console.info("Cannot refresh GoalsByPeriodWidget because game is not available")
        return;
      }

      const payload = {
        whoTeamUID: game.homeTeamUID,
        againstTeamUID: game.awayTeamUID,
        leagueUID: perspectiveStore().getParameterLeagueValue(this.item.uid),
        location: perspectiveStore().getParameterValue(this.item.uid, 'location'),
        period: perspectiveStore().getParameterValue(this.item.uid, 'period'),
        sport: perspectiveStore().getCurrentSport()
      }
      axiosService.put('/wid/goals-by-period', payload).then(response => {
        this.data = response.data
        wds.put(this.item.uid, contextStore().gameUID, this.data)

        this._updateChart()
      })
    },
    _updateChart() {

      const homeForData = [
        this.data.home.forP1Goals?.toFixed(2),
        this.data.home.forP2Goals?.toFixed(2)
      ]
      const awayForData = [
        this.data.away.forP1Goals?.toFixed(2),
        this.data.away.forP2Goals?.toFixed(2)
      ]
      const homeAgainstData = [
        this.data.home.againstP1Goals?.toFixed(2),
        this.data.home.againstP2Goals?.toFixed(2)
      ]
      const awayAgainstData = [
        this.data.away.againstP1Goals?.toFixed(2),
        this.data.away.againstP2Goals?.toFixed(2)
      ]
      switch (this._currentSport) {
        case 'FOOTBALL':
          break
        case 'HOCKEY':
          homeForData.push(this.data.home.forP3Goals?.toFixed(2))
          awayForData.push(this.data.away.forP3Goals?.toFixed(2))
          homeAgainstData.push(this.data.home.againstP3Goals?.toFixed(2))
          awayAgainstData.push(this.data.away.againstP3Goals?.toFixed(2))
          break
        case 'BASKETBALL':
          homeForData.push(this.data.home.forP3Goals?.toFixed(2))
          homeForData.push(this.data.home.forP4Goals?.toFixed(2))
          awayForData.push(this.data.away.forP3Goals?.toFixed(2))
          awayForData.push(this.data.away.forP4Goals?.toFixed(2))
          homeAgainstData.push(this.data.home.againstP3Goals?.toFixed(2))
          homeAgainstData.push(this.data.home.againstP4Goals?.toFixed(2))
          awayAgainstData.push(this.data.away.againstP3Goals?.toFixed(2))
          awayAgainstData.push(this.data.away.againstP4Goals?.toFixed(2))
          break
        case 'TENNIS':
          homeForData.push(this.data.home.forP3Goals?.toFixed(2))
          homeForData.push(this.data.home.forP4Goals?.toFixed(2))
          homeForData.push(this.data.home.forP5Goals?.toFixed(2))
          awayForData.push(this.data.away.forP3Goals?.toFixed(2))
          awayForData.push(this.data.away.forP4Goals?.toFixed(2))
          awayForData.push(this.data.away.forP5Goals?.toFixed(2))
          homeAgainstData.push(this.data.home.againstP3Goals?.toFixed(2))
          homeAgainstData.push(this.data.home.againstP4Goals?.toFixed(2))
          homeAgainstData.push(this.data.home.againstP5Goals?.toFixed(2))
          awayAgainstData.push(this.data.away.againstP3Goals?.toFixed(2))
          awayAgainstData.push(this.data.away.againstP4Goals?.toFixed(2))
          awayAgainstData.push(this.data.away.againstP5Goals?.toFixed(2))
          break
      }

      // if all data in homeForData is null, we don't display the chart
      if (homeForData.every(x => x === null || x === undefined)) {
        return
      }
      // if all data in awayForData is null, we don't display the chart
      if (awayForData.every(x => x === null || x === undefined)) {
        return
      }

      this.chart.series = [
        {
          name: 'Home marque',
          group: 'home',
          data: homeForData
        },
        {
          name: 'Away marque',
          group: 'away',
          data: awayForData
        },
        {
          name: 'Home encaisse',
          group: 'home',
          data: homeAgainstData
        },
        {
          name: 'Away encaisse',
          group: 'away',
          data: awayAgainstData
        }
      ]

      this.chart.key++
    }
  },

}
</script>

<template>
  <div style="height: 100%">
    <div class="widget-header">
      {{ _pointName }} / période
      <div class="widget-header-params flex">
        <widget-period-param-button :widget="item"/>
        <widget-location-param-button :widget="item"/>
        <widget-league-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content flex" v-else>
      <apexchart
          :key="chart.key"
          ref="teams-perfomance-chart-widget"
          :width="chart.width"
          :type="chart.type"
          :options="chart.options"
          :series="chart.series"/>
    </div>
  </div>
</template>

<style scoped>

</style>